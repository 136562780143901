import { CharityadminService } from './../../services/charityadmin.service';
import { SecccharityService } from './../../services/secccharity.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-instructions',
  templateUrl: './instructions.component.html',
  styleUrls: ['./instructions.component.css']
})
export class InstructionsComponent implements OnInit {

  constructor(private router: Router,
              private secccharityService : SecccharityService,
              private charityadminService : CharityadminService
              ) { }

  newAppSubscription : Subscription = new Subscription();


  ngOnInit() {
    this.charityadminService.isAdmin = false;  // Reset because Admin may have previously logged in
  }
  ngAfterViewInit() {
    this.secccharityService.scrollToTopFast();
  }

  startNewApp() {

    this.secccharityService.resetBrowserSession();  //reset Applicaiton session in browser


    this.newAppSubscription = this.secccharityService.startNewApplication()  //reset Applicaiton session in server
                           .subscribe((response) => {
                               console.log('Starting new application..');
                               this.router.navigate(['apptype']);
                           },
                           (err) =>{
                              console.log(err);
                           });
  }


  ngOnDestroy() {

    if (this.newAppSubscription != undefined) {
             this.newAppSubscription.unsubscribe();
    }
  }

}
