<style>
  .my-drop-zone {
    border: dotted 3px lightgray;
  }
  .nv-file-over {
    border: dotted 3px red;
  } /* Default class applied to drop zones on over */
  .another-file-over-class {
    border: dotted 3px green;
  }

  html,
  body {
    height: 100%;
  }
</style>

<div class="container">
  <div class="navbar navbar-default">
    <div class="navbar-header">
      <!-- Remove -->
    </div>
  </div>

  <div class="row">
    <span style="font-size: 75%; color: Blue">File name to less than 100 characters.</span>
    <!--Show upload section only if NOT Admin-->
    <div class="col-md-3" *ngIf="!this.charityadminService.isAdmin">
      <input
        type="file"
        #uploadFile
        ng2FileSelect
        [uploader]="uploader"
        [multiple]="allowMultiple"
      /><br />
    </div>

    <div class="col-md-9" style="margin-bottom: 40px" class="uploadbox">
      <table class="table">
        <div></div>
        <div id="spinarea-secc"></div>
        <tbody>
          <tr *ngFor="let item of uploader.queue">
            <td>
              <span *ngIf="item.isSuccess"
                ><img src="assets/img/green-check.webp" width="10"
              /></span>
              <strong>{{ item?.file?.name }}</strong>
            </td>
            <td *ngIf="uploader.options.isHTML5" nowrap>
              {{ item?.file?.size / 1024 / 1024 | number : ".2" }} MB
            </td>
            <td *ngIf="uploader.options.isHTML5">
              <div class="progress" style="margin-bottom: 0">
                <div
                  class="progress-bar"
                  role="progressbar"
                  [ngStyle]="{ width: item.progress + '%' }"
                ></div>
              </div>
            </td>
            <td class="text-center">
              <span *ngIf="item.isSuccess"
                ><i class="glyphicon glyphicon-ok"></i
              ></span>
              <span *ngIf="item.isCancel"
                ><i class="glyphicon glyphicon-ban-circle"></i
              ></span>
              <span *ngIf="item.isError"
                ><i class="glyphicon glyphicon-remove"></i
              ></span>
            </td>
            <td nowrap>
              <button
                type="button"
                class="btn btn-success btn-xs"
                (click)="uploadToServer(item)"
                [disabled]="item.isReady || item.isUploading || item.isSuccess"
              >
                <span class="glyphicon glyphicon-upload"></span> Upload
              </button>

              <button
                type="button"
                class="btn btn-danger btn-xs"
                (click)="removeFromServer(item)"
              >
                <span class="glyphicon glyphicon-trash"></span> Remove
              </button>
            </td>
          </tr>
        </tbody>
      </table>

      <div *ngIf="this.charityadminService.isAdmin">
        <!-- Show only if admin-->
        <table class="table">

          <tr *ngFor="let attachment of attachments">
            <td>
              <mat-icon matTooltip="Download" class="material-icons download" style="cursor:pointer" matSuffix
                  (click)="downloadAttachment(attachment.name,attachment.key)"
                >download</mat-icon
              >
            </td>
          <!--
            <td>
              <mat-icon matTooltip="Delete disabled" class="material-icons delete"  style="cursor:crosshair" matSuffix
                >delete</mat-icon
              >
            </td>
          -->
            <td><font color="blue">{{ attachment.name }}</font></td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</div>
