<tbody>
  <tr>
    <td>
      <table
        border="0"
        cellspacing="0"
        style="background-color: White"
        cellpadding="5"
        width="800px"
        align="center"
      >
        <tbody>
          <tr>
            <td>
              <div
                id="ctl00_ValidationSummary1"
                style="color: Red"
                [hidden]="!isValidationError"
              >
                <h4>Please correct the following errors</h4>
                <ul>
                  <li *ngFor="let error of errMessages">
                    {{ error }}
                  </li>
                </ul>
              </div>
            </td>
          </tr>
        </tbody>
      </table>

      <form>
        <div
          id="ctl00_pageContent_pnlContent"
          onkeypress="javascript:return WebForm_FireDefaultButton(event, 'ctl00_pageContent_btnNext')"
        >
          <table width="100%">
            <tbody>
              <tr>
                <td style="text-align: center">
                  <h3>
                    SECC INDEPENDENT CHARITY, FEDERATION &amp; FEDERATION
                    CHARITY PROFILE FORM
                  </h3>
                  <br />
                  (This form is required for each individual charity regardless
                  if it is submitted independently or through a federation.)<br />
                </td>
              </tr>
              <tr>
                <td style="font-size: smaller; color: red"></td>
              </tr>
            </tbody>
          </table>
          <h3>1. Name &amp; Contact Information:</h3>
          <div id="ctl00_pageContent_pnlInfo"></div>
          <table width="100%">
            <tbody id="top">
              <tr>
                <td class="label">
                  <span id="ctl00_pageContent_txtName_uclbl"
                    >Name of Charity for Donor Guide</span
                  >:
                </td>
                <td id="ctl00_pageContent_txtName_tdTxt" colspan="2">
                  <input
                    name="title"
                    type="text"
                    maxlength="80"
                    id="title"
                    style="width: 300px"
                    [(ngModel)]="generalInfoModel.title"
                  /><span
                    id="ctl00_pageContent_txtName_ucrev"
                    style="color: Red; display: none"
                  ></span>
                  <span
                    id="ctl00_pageContent_txtName_ucrfv"
                    style="color: Red; display: none"
                  ></span>
                </td>
              </tr>
              <tr>
                <td class="label">
                  <span id="ctl00_pageContent_txtLegalName_uclbl"
                    >Legal Charity Name</span
                  >:
                </td>
                <td id="ctl00_pageContent_txtLegalName_tdTxt" colspan="2">
                  <input
                    name="legalName"
                    type="text"
                    maxlength="80"
                    id="legalName"
                    style="width: 300px"
                    [(ngModel)]="generalInfoModel.legalName"
                  /><span
                    id="ctl00_pageContent_txtLegalName_ucrev"
                    style="color: Red; display: none"
                  ></span>
                  <span
                    id="ctl00_pageContent_txtLegalName_ucrfv"
                    style="color: Red; display: none"
                  ></span>
                </td>
              </tr>
              <tr id="ctl00_pageContent_tdFed" [hidden]="! isShowFedName">
                <td class="label">
                  <span id="ctl00_pageContent_uclbl">Federation Name</span>:
                </td>
                <td colspan="2">
                  <select
                    name="ctl00$pageContent$ddlFederations"
                    id="ctl00_pageContent_ddlFederations"
                    style="width: 306px"
                    [(ngModel)]="generalInfoModel.federationId"
                  >
                    <option value="">select</option>
                    <option *ngFor="let fed of federations" [value]=fed.id>{{fed.title}}</option>
                  </select>
                  <span
                    id="ctl00_pageContent_ucrfv"
                    style="color: Red; display: none"
                  ></span>
                </td>
              </tr>

              <tr>
                <td class="label">
                  <span id="ctl00_pageContent_txtStreetAddress_uclbl"
                    >Street Address</span
                  >:
                </td>
                <td id="ctl00_pageContent_txtStreetAddress_tdTxt" colspan="2">
                  <input
                    name="address"
                    type="text"
                    maxlength="50"
                    id="address"
                    style="width: 300px"
                    [(ngModel)]="generalInfoModel.address"
                  /><span
                    id="ctl00_pageContent_txtStreetAddress_ucrev"
                    style="color: Red; display: none"
                  ></span>
                  <span
                    id="ctl00_pageContent_txtStreetAddress_ucrfv"
                    style="color: Red; display: none"
                  ></span>
                </td>
              </tr>
              <tr>
                <td class="label">
                  <span id="ctl00_pageContent_txtCity_uclbl">City</span>:
                </td>
                <td id="ctl00_pageContent_txtCity_tdTxt">
                  <input
                    name="city"
                    type="text"
                    maxlength="50"
                    id="city"
                    style="width: 170px"
                    [(ngModel)]="generalInfoModel.city"
                  /><span
                    id="ctl00_pageContent_txtCity_ucrev"
                    style="color: Red; display: none"
                  ></span>
                  <span
                    id="ctl00_pageContent_txtCity_ucrfv"
                    style="color: Red; display: none"
                  ></span>
                </td>
              </tr>

              <tr>
                <td class="label">
                  <span id="ctl00_pageContent_txtState_uclbl">State</span>:
                </td>
                <td id="ctl00_pageContent_txtState_tdTxt">
                  <select
                    name="state"
                    type="text"
                    maxlength="50"
                    id="state"
                    style="width: 170px"
                    [(ngModel)]="generalInfoModel.state"
                  >
                  <option value="" disabled selected>select</option>

                  <option value="AL">Alabama</option>
                  <option value="AK">Alaska</option>
                  <option value="AZ">Arizona</option>
                  <option value="AR">Arkansas</option>
                  <option value="CA">California</option>
                  <option value="CO">Colorado</option>
                  <option value="CT">Connecticut</option>
                  <option value="DE">Delaware</option>
                  <option value="DC">District Of Columbia</option>
                  <option value="FL">Florida</option>
                  <option value="GA">Georgia</option>
                  <option value="HI">Hawaii</option>
                  <option value="ID">Idaho</option>
                  <option value="IL">Illinois</option>
                  <option value="IN">Indiana</option>
                  <option value="IA">Iowa</option>
                  <option value="KS">Kansas</option>
                  <option value="KY">Kentucky</option>
                  <option value="LA">Louisiana</option>
                  <option value="ME">Maine</option>
                  <option value="MD">Maryland</option>
                  <option value="MA">Massachusetts</option>
                  <option value="MI">Michigan</option>
                  <option value="MN">Minnesota</option>
                  <option value="MS">Mississippi</option>
                  <option value="MO">Missouri</option>
                  <option value="MT">Montana</option>
                  <option value="NE">Nebraska</option>
                  <option value="NV">Nevada</option>
                  <option value="NH">New Hampshire</option>
                  <option value="NJ">New Jersey</option>
                  <option value="NM">New Mexico</option>
                  <option value="NY">New York</option>
                  <option value="NC">North Carolina</option>
                  <option value="ND">North Dakota</option>
                  <option value="OH">Ohio</option>
                  <option value="OK">Oklahoma</option>
                  <option value="OR">Oregon</option>
                  <option value="PA">Pennsylvania</option>
                  <option value="RI">Rhode Island</option>
                  <option value="SC">South Carolina</option>
                  <option value="SD">South Dakota</option>
                  <option value="TN">Tennessee</option>
                  <option value="TX">Texas</option>
                  <option value="UT">Utah</option>
                  <option value="VT">Vermont</option>
                  <option value="VA">Virginia</option>
                  <option value="WA">Washington</option>
                  <option value="WV">West Virginia</option>
                  <option value="WI">Wisconsin</option>
                  <option value="WY">Wyoming</option>
                  <option value="" disabled>US Outlying Territories</option>
                  <option value="AS">American Samoa</option>
                  <option value="GU">Guam</option>
                  <option value="MP">Northern Mariana Islands</option>
                  <option value="PR">Puerto Rico</option>
                  <option value="UM">United States Minor Outlying Islands</option>
                  <option value="VI">Virgin Islands</option>
                </select>
                </td>
              </tr>
              <tr>
                <td class="label">
                  <span id="ctl00_pageContent_txtZip_uclbl">Zip Code</span>:
                </td>
                <td id="ctl00_pageContent_txtZip_tdTxt">
                  <input
                    name="zip"
                    type="text"
                    maxlength="10"
                    id="zip"
                    style="width: 170px"
                    [(ngModel)]="generalInfoModel.zip"
                  /><span
                    id="ctl00_pageContent_txtZip_ucrev"
                    style="color: Red; display: none"
                  ></span>
                  <span
                    id="ctl00_pageContent_txtZip_ucrfv"
                    style="color: Red; display: none"
                  ></span>
                </td>
              </tr>
              <tr>
                <td class="label">
                  <span id="ctl00_pageContent_txtContactName_uclbl"
                    >Contact Name</span
                  >:
                </td>
                <td id="ctl00_pageContent_txtContactName_tdTxt">
                  <input
                    name="contactName"
                    type="text"
                    maxlength="50"
                    id="contactName"
                    style="width: 170px"
                    [(ngModel)]="generalInfoModel.contactName"
                  /><span
                    id="ctl00_pageContent_txtContactName_ucrev"
                    style="color: Red; display: none"
                  ></span>
                  <span
                    id="ctl00_pageContent_txtContactName_ucrfv"
                    style="color: Red; display: none"
                  ></span>
                </td>

                <td class="label">
                  <span id="ctl00_pageContent_txtContactEmail_uclbl"
                    >Contact Email Address</span
                  >:
                </td>
                <td id="ctl00_pageContent_txtContactEmail_tdTxt">
                  <input
                    name="contactEmail"
                    type="text"
                    maxlength="50"
                    id="contactEmail"
                    style="width: 170px"
                    [(ngModel)]="generalInfoModel.contactEmail"
                  /><span
                    id="ctl00_pageContent_txtContactEmail_ucrev"
                    style="color: Red; display: none"
                  ></span>
                  <span
                    id="ctl00_pageContent_txtContactEmail_ucrfv"
                    style="color: Red; display: none"
                  ></span>
                </td>
              </tr>
              <tr>
                <td class="label">
                  <span id="ctl00_pageContent_txtContactPhone_uclbl"
                    >Contact Phone Number</span
                  >:
                </td>
                <td id="ctl00_pageContent_txtContactPhone_tdTxt">
                  <input
                    name="contactPhone"
                    type="text"
                    maxlength="50"
                    id="contactPhone"
                    style="width: 170px"
                    [(ngModel)]="generalInfoModel.contactPhone"
                  /><span
                    id="ctl00_pageContent_txtContactPhone_ucrev"
                    style="color: Red; display: none"
                  ></span>
                  <span
                    id="ctl00_pageContent_txtContactPhone_ucrfv"
                    style="color: Red; display: none"
                  ></span>
                </td>

                <td class="label">
                  <span id="ctl00_pageContent_txtGenPhone_uclbl"
                    >Charity General Phone Number</span
                  >:
                </td>
                <td id="ctl00_pageContent_txtGenPhone_tdTxt">
                  <input
                    name="phone"
                    type="text"
                    maxlength="50"
                    id="phone"
                    style="width: 170px"
                    [(ngModel)]="generalInfoModel.phone"
                  /><span
                    id="ctl00_pageContent_txtGenPhone_ucrev"
                    style="color: Red; display: none"
                  ></span>
                  <span
                    id="ctl00_pageContent_txtGenPhone_ucrfv"
                    style="color: Red; display: none"
                  ></span>
                </td>
              </tr>
              <tr>
                <td class="label">
                  <span id="ctl00_pageContent_txtGenEmail_uclbl"
                    >General Email Address</span
                  >:
                </td>
                <td id="ctl00_pageContent_txtGenEmail_tdTxt">
                  <input
                    name="email"
                    type="text"
                    maxlength="50"
                    id="email"
                    style="width: 170px"
                    [(ngModel)]="generalInfoModel.email"
                  /><span
                    id="ctl00_pageContent_txtGenEmail_ucrev"
                    style="color: Red; display: none"
                  ></span>
                  <span
                    id="ctl00_pageContent_txtGenEmail_ucrfv"
                    style="color: Red; display: none"
                  ></span>
                </td>

                <td class="label">
                  <span id="ctl00_pageContent_txtWebsite_uclbl"
                    >Charity Website</span
                  >:
                </td>
                <td id="ctl00_pageContent_txtWebsite_tdTxt">
                  <input
                    name="url"
                    type="text"
                    maxlength="50"
                    id="url"
                    style="width: 170px"
                    [(ngModel)]="generalInfoModel.url"
                  /><span
                    id="ctl00_pageContent_txtWebsite_ucrev"
                    style="color: Red; display: none"
                  ></span>
                  <span
                    id="ctl00_pageContent_txtWebsite_ucrfv"
                    style="color: Red; display: none"
                  ></span>
                </td>
              </tr>

              <tr>
                <td colspan="4">
                  <hr />
                  &nbsp;
                </td>
              </tr>
            </tbody>
            <tbody id="bottom" [hidden]="!isNameContactInfoFilled()">
              <tr>
                <td colspan="4">
                  <h3>2. Services Provided: (Please Select All That Apply)</h3>

                  <table id="ctl00_pageContent_cblServices" border="0">
                      <tr *ngFor="let row of serviceCodeOptions; let i=index">
                        <td *ngFor="let item of row; let j=index">
                         <input
                            type="checkbox"
                            id="{{item.name}}"
                            [value]="item.code"
                            [checked]="doesServiceCodesContain(item.code)"
                            (click)="setServiceCodeCheckBox($event,item.code)"
                          /><label>{{item.name}}</label>
                        </td>
                    </tr>
                  </table>

                  <span
                    id="ctl00_pageContent_rfvcblServices"
                    style="color: Red; display: none"
                  ></span>
                </td>
              </tr>
              <tr>
                <td colspan="4">
                  <hr />
                  &nbsp;
                </td>
              </tr>
              <tr>
                <td colspan="4">
                  <h3>
                    3. Administrative Overhead Percentage:
                    <input
                      name="percentage"
                      type="text"
                      id="percentage"
                      [(ngModel)]="generalInfoModel.percentage"
                    />%
                    <span
                      id="ctl00_pageContent_rxOverhead"
                      style="color: Red; display: none"
                    ></span>
                    <span
                      id="ctl00_pageContent_rvOverhead"
                      style="color: Red; display: none"
                    ></span>
                  </h3>
                </td>
              </tr>
              <tr>
                <td colspan="4">
                  <hr />
                  &nbsp;
                </td>
              </tr>
              <tr>
                <td colspan="4">
                  <h3>
                    4. Enter your 200 character charity description. This field
                    will be used for word searches in our online system. Please
                    be sure to include the types of services you provide and to
                    whom you provide them.
                  </h3>
                  <textarea
                    name="narrative"
                    rows="3"
                    cols="20"
                    id="narrative"
                    style="width: 80%"
                    [(ngModel)]="generalInfoModel.narrative"
                    maxlength="200"
                  ></textarea>
                  <span
                    id="ctl00_pageContent_rgCharityDesc"
                    style="color: Red; display: none"
                  ></span>
                  <span
                    id="ctl00_pageContent_rfCharityDesc"
                    style="color: Red; display: none"
                  ></span>
                </td>
              </tr>
              <tr>
                <td colspan="4">
                  <hr />
                  &nbsp;
                </td>
              </tr>
              <tr>
                <td colspan="4">
                  <h3>
                    5. Services Provided in Which County(ies)/Location or
                    Indicate either Statewide, National or International:
                  </h3>
                  <table id="ctl00_pageContent_cblCounties" border="0">
                    <tbody>
                        <tr *ngFor="let row of countyOptions; let i=index">
                            <td *ngFor="let item of row; let j=index">
                            <input
                                type="checkbox"
                                id="{{item.name}}"
                                [value]="item.fipsCode"
                                [checked]="doesCountyCodesContain(item.fipsCode)"
                                (click)="setCountyCheckBox($event,item.fipsCode)"
                              /><label>{{item.name}}</label>
                            </td>
                        </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
              <tr>
                <td colspan="4">
                  <table id="ctl00_pageContent_cblLocations" border="0">
                    <tbody>
                      <tr>
                        <td>
                          <input
                            id="locations_0"
                            type="radio"
                            name="locations"
                            value="0"
                            [checked]="generalInfoModel.locations=='0'"
                            [(ngModel)]="generalInfoModel.locations"
                          /><label for="locations_0">STATEWIDE</label>
                        </td>
                        <td>
                          <input
                            id="locations_1"
                            type="radio"
                            name="locations"
                            value="1"
                            [checked]="generalInfoModel.locations=='1'"
                            [(ngModel)]="generalInfoModel.locations"
                          /><label for="locations_1">NATIONAL</label>
                        </td>
                        <td>
                          <input
                            id="locations_2"
                            type="radio"
                            name="locations"
                            value="2"
                            [checked]="generalInfoModel.locations=='2'"
                            [(ngModel)]="generalInfoModel.locations"
                          /><label for="locations_2">INTERNATIONAL</label>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
              <tr>
                <td colspan="4">
                  <hr />
                  &nbsp;
                </td>
              </tr>
              <tr>
                <td colspan="4">
                  <h3>
                    6. Would you like to have your color logo appear beside your
                    name in the SECC Click and Give system for a $25 handling
                    fee?:
                  </h3>
                  <table
                    id="ctl00_pageContent_ynLogo_rblYesNo"
                    cellpadding="5"
                    border="0"
                  >
                    <tbody>
                      <tr>
                        <td>
                          <input
                            id="logo_0"
                            type="radio"
                            name="logo"
                            value="true"
                            [checked]="generalInfoModel.logo=='true'"
                            [(ngModel)]="generalInfoModel.logo"
                          /><label for="logo_0">YES</label>
                        </td>
                        <td>
                          <input
                            id="logo_1"
                            type="radio"
                            name="logo"
                            value="false"
                            [checked]="generalInfoModel.logo=='false'"
                            [(ngModel)]="generalInfoModel.logo"
                          /><label for="logo_1">NO</label>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <span
                    id="ctl00_pageContent_ynLogo_rfvYesNo"
                    style="color: Red; display: none"
                  ></span>
                </td>
              </tr>
              <tr>
                <td colspan="4">
                  <hr />
                  &nbsp;
                </td>
              </tr>
              <tr>
                <td colspan="4">
                  <h3>
                    7. Each year state employees host donor/ASK meetings. We may
                    invite charity speakers to attend and give a brief overview
                    of their charity and the services the charity provides.
                    Indicate if you’d like to be potentially invited by state
                    employees to speak at SECC donor meetings.
                  </h3>
                  <table
                    id="ctl00_pageContent_ynSpeak_rblYesNo"
                    cellpadding="5"
                    border="0"
                  >
                    <tbody>

                      <tr>
                        <td>
                          <input
                            id="speakerBureau_1"
                            type="radio"
                            name="speakerBureau"
                            value="true"
                            [checked]="generalInfoModel.speakerBureau=='true'"
                            [(ngModel)]="generalInfoModel.speakerBureau"
                          /><label for="speakerBureau_1">YES</label>
                        </td>
                        <td>
                          <input
                            id="speakerBureau_0"
                            type="radio"
                            name="speakerBureau"
                            value="false"
                            [checked]="generalInfoModel.speakerBureau=='false'"
                            [(ngModel)]="generalInfoModel.speakerBureau"
                          /><label for="speakerBureau_0">NO</label>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <span
                    id="ctl00_pageContent_ynSpeak_rfvYesNo"
                    style="color: Red; display: none"
                  ></span>
                </td>
              </tr>
              <tr>
                <td colspan="4">
                  <table id="ctl00_pageContent_cblSpeakCounties" border="0">
                    <tbody>
                      <tr *ngFor="let row of speakCountyOptions; let i=index">
                            <td *ngFor="let item of row; let j=index">
                            <input
                                type="checkbox"
                                id="{{item.name}}"
                                [value]="item.fipsCode"
                                [checked]="doesSpeakCountyCodesContain(item.fipsCode)"
                                (click)="setSpeakCountyCheckBox($event,item.fipsCode)"
                              /><label>{{item.name}}</label>
                            </td>
                      </tr>
                  </table>
                </td>
              </tr>
              <tr>
                <td colspan="4">
                  <hr />
                  &nbsp;
                </td>
              </tr>
              <tr>
                <td colspan="4">
                  <h3>
                    8. Our SECC policy requires that you provide your Federal
                    EIN/Tax ID#.
                  </h3>
                </td>
              </tr>

              <tr>
                <td colspan="4">
                  <table width="100%">
                    <tbody>
                      <tr>
                        <td class="label">
                          <span id="ctl00_pageContent_txtFederalTaxID_uclbl"
                            >Federal EIN/Tax ID#</span
                          >:
                        </td>
                        <td id="ctl00_pageContent_txtFederalTaxID_tdTxt">
                          <input
                            name="taxID"
                            type="text"
                            maxlength="50"
                            id="taxID"
                            style="width: 170px"
                            [(ngModel)]="generalInfoModel.taxID"
                          /><span
                            id="ctl00_pageContent_txtFederalTaxID_ucrev"
                            style="color: Red; display: none"
                          ></span>
                          <span
                            id="ctl00_pageContent_txtFederalTaxID_ucrfv"
                            style="color: Red; display: none"
                          ></span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
              <tr>
                <td colspan="4">
                  <hr />
                  &nbsp;
                </td>
              </tr>
            </tbody>
            <tbody id="buttonbody">
              <tr>
                <td colspan="4" style="text-align: right">
                  <input
                    type="image"
                    width="100"
                    name="ctl00$pageContent$btnNext"
                    id="ctl00_pageContent_btnNext"
                    src="assets/img/continue-button.jpg"
                    (click)="continue()"
                    style="border-width: 0px"
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </form>

      <style type="text/css">
        .row {
          clear: both;
          width: 100%;
          overflow: hidden;
          margin: 5px 0;
        }
        .label {
          float: none;
          text-align: left;
          margin-right: 10px;
          background-color: Gray;
          color: White;
        }
        .value {
          float: left;
          text-align: left;
        }
      </style>
    </td>
  </tr>
</tbody>
