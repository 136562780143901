import { stringify } from "querystring";

export class AdminCertsModel {
  public constructor() {}

  respP: boolean; // 0 or 1
  explainP: string;

  respA: boolean;
  explainA: string;

  respB: boolean;
  explainB: string;

  respC: boolean;
  explainC: string;

  respD: string;
  explainD: string;

  respE: boolean;
  explainE: string;

  respF: boolean;
  explainF: string;

  respG: boolean;
  explainG: string;
}


