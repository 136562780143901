import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Observable, Subscription } from 'rxjs';
import { SoftFileReqModel } from '../../app/secconlineapp/models/SoftFileReqModel';
import { AdminCertsModel } from '../secconlineapp/models/AdminCertsModel';
import { FinCertsModel } from '../secconlineapp/models/FinCertsModel';
import { FinCertsFcModel } from '../secconlineapp/models/FinCertsFcModel';
import { GeneralInfoModel } from '../secconlineapp/models/GeneralInfoModel';
import { SubmitModel } from '../secconlineapp/models/SubmitModel';

declare var Spinner : any; // Spinner from spin.js
/*
* Global service shared across all components
*/
@Injectable({
  providedIn: 'root', //This is important or null error
})
export class SecccharityService {

  //Page objects
  appType: string;
  generalInfoModel: GeneralInfoModel;
  adminCertsModel: AdminCertsModel;
  finCertsModel: FinCertsModel;
  finCertsfcModel: FinCertsFcModel;
  submitModel: SubmitModel;
  campaignYear: number;

  //Map for queue state of uploaded files
  uploaderQueueMap : Map<string, any> = new Map<string, any>();


  constructor(private http: HttpClient) {
  }

  public  resetBrowserSession() {

    this.appType = "";
    this.generalInfoModel = new GeneralInfoModel() ;
    this.adminCertsModel = new AdminCertsModel() ;
    this.finCertsModel = new FinCertsModel();
    this.finCertsfcModel = new FinCertsFcModel();
    this.submitModel = new SubmitModel();
    this.campaignYear = 0;
    this.uploaderQueueMap  = new Map<string, any>();
  }

  //Start new application (server session)
  public startNewApplication() {

    const newAppInitEndpoint = environment.server_url + 'secconlineapp/createnewapp';
    const headers = new Headers({ 'Content-type': 'application/json' });
    const httpOptions = {
            headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    };
    return this.http.post(newAppInitEndpoint, httpOptions);
  }



  //App Type
  public setAppType(appType: string): Observable<any> {
    const setAppTypeEndpoint =
      environment.server_url + 'secconlineapp/setapptype';
    const bodyString = JSON.stringify({ appType: appType });
    console.log(bodyString);
    const headers = new Headers({ 'Content-type': 'application/json' });
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    };
    return this.http.post(setAppTypeEndpoint, bodyString, httpOptions);
  }

  //Get Federaions
  public getFederations(): Observable<any> {
    const federationsEndpoint =
      environment.server_url + 'secconlineapp/getfederations';
    const headers = new Headers({ 'Content-type': 'application/json' });
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    };
    return this.http.get(federationsEndpoint, httpOptions);
  }

  //Service Codes
  public getServiceCodes() {
    const svcCodesEndpoint =
      environment.server_url + 'secconlineapp/getservicecodes';
    const headers = new Headers({ 'Content-type': 'application/json' });
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    };
    return this.http.get(svcCodesEndpoint, httpOptions);
  }

  //Service Codes
  public getCounties(onlyCounty: boolean) {
    const countiesEndpoint =
      environment.server_url + 'secconlineapp/getcounties?onlyCounty='+ onlyCounty;
    const headers = new Headers({ 'Content-type': 'application/json' });
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    };
    return this.http.get(countiesEndpoint, httpOptions);
  }

    //Campaign Year
    public getCampaignYear() {
      const campaignYearEndpoint =
        environment.server_url + 'secconlineapp/fincerts/getcampaignyear';
      const headers = new Headers({ 'Content-type': 'text/html' });
      const httpOptions = {
        headers: new HttpHeaders({ 'Content-Type': 'text/html' }),
      };
      return this.http.get(campaignYearEndpoint, httpOptions);
    }

  //General Info
  public setGeneralInfo(generalInfoModel: GeneralInfoModel): Observable<any> {
    const setGeneralInfoEndpoint =
      environment.server_url + 'secconlineapp/setgeneralinfo';
    const bodyString = JSON.stringify(generalInfoModel);
    const headers = new Headers({ 'Content-type': 'application/json' });
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    };

    return this.http.post(setGeneralInfoEndpoint, bodyString, httpOptions);
  }

  //Soft File Req
  public valSoftFileReq(): Observable<any> {
    const setSoftFileReqEndpoint =
      environment.server_url + 'secconlineapp/softfilereq/validateSoftFileReq';

    const headers = new Headers({ 'Content-type': 'application/json' });
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    };

    return this.http.post(setSoftFileReqEndpoint, httpOptions);
  }

  //Admin Certs
  public updateAdminCert(adminCertsModel: AdminCertsModel): Observable<any> {
    const adminCertsEndpoint =
      environment.server_url + 'secconlineapp/admincerts/updateAdminCert';
    const bodyString = JSON.stringify(adminCertsModel);
    const headers = new Headers({ 'Content-type': 'application/json' });
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    };
    return this.http.post(adminCertsEndpoint, bodyString, httpOptions);
  }

  //Fin Certs
  public updateFinCert(finCertsModel: FinCertsModel): Observable<any> {
    const finCertsEndpoint =
      environment.server_url + 'secconlineapp/fincerts/updateFinCert';
    const bodyString = JSON.stringify(finCertsModel);
    const headers = new Headers({ 'Content-type': 'application/json' });
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    };
    return this.http.post(finCertsEndpoint, bodyString, httpOptions);
  }

    //Fin Certs FC
    public updateFinCertFc(finCertsFcModel: FinCertsFcModel): Observable<any> {
      const finCertsFcEndpoint =
        environment.server_url + 'secconlineapp/fincerts/updateFinCertFc';
      const bodyString = JSON.stringify(finCertsFcModel);
      const headers = new Headers({ 'Content-type': 'application/json' });
      const httpOptions = {
        headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
      };
      return this.http.post(finCertsFcEndpoint, bodyString, httpOptions);
    }

  //Submit
  ///rest/secconlineapp/submit
  public submitApplication(submitModel: SubmitModel): Observable<any> {
    const submitEndpoint =
      environment.server_url + 'secconlineapp/submit/submitApplication';
    const bodyString = JSON.stringify(submitModel);
    const headers = new Headers({ 'Content-type': 'application/json' });
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    };
    return this.http.post(submitEndpoint, bodyString, httpOptions);
  }

  //ThankYou
  public getNonProfitId() {
    const countiesEndpoint =
      environment.server_url + 'secconlineapp/submit/getNonProfitID';
    const headers = new Headers({ 'Content-type': 'application/json' });
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    };
    return this.http.get(countiesEndpoint, httpOptions);
  }


  //General UI stuff
  scrollToTop() {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }
 //General UI stuff
 scrollToTopFast() {
  window.scroll({
    top: 0,
    left: 0,
    behavior: 'auto'
  });
}

  //////SPINNER//////
  startSpinner(spinarea) {
    var target = document.getElementById(spinarea);
    var spinner = new Spinner(this.opts).spin();
    target.appendChild(spinner.el);
    return spinner;
  }

  stopSpinner(spinner) {
    spinner.stop();
  }

  readonly opts = {
    lines: 13, // The number of lines to draw
    length: 9, // The length of each line
    width: 3, // The line thickness
    radius: 16, // The radius of the inner circle
    scale: 1, // Scales overall size of the spinner
    corners: 1, // Corner roundness (0..1)
    color: "#888888", // CSS color or array of colors
    fadeColor: "transparent", // CSS color or array of colors
    speed: 1, // Rounds per second
    rotate: 0, // The rotation offset
    animation: "spinner-line-fade-quick", // The CSS animation name for the lines
    direction: 1, // 1: clockwise, -1: counterclockwise
    zIndex: 2e9, // The z-index (defaults to 2000000000)
    className: "spinner", // The CSS class to assign to the spinner
    top: "50%", // Top position relative to parent
    left: "50%", // Left position relative to parent
    shadow: "0 0 1px transparent", // Box-shadow for the lines
    position: "absolute", // Element positioning
  };
}
