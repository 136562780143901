import { SeccfileuploaderComponent } from './../seccfileuploader/seccfileuploader.component';
import { SecccharityService } from './../../services/secccharity.service';
import { SessiontimeoutService } from 'src/app/services/sessiontimeout.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { SoftFileReqModel } from '../models/SoftFileReqModel';



@Component({
  selector: 'app-softfilereq',
  templateUrl: './softfilereq.component.html',
  styleUrls: ['./softfilereq.component.css'],
})
export class SoftfilereqComponent implements OnInit {
  public softFileReqModel: SoftFileReqModel;

  @ViewChild('digtlpics')
  digtlpicsRef: SeccfileuploaderComponent;

  @ViewChild('story')
  storyRef: SeccfileuploaderComponent;

  @ViewChild('logo')
  logoRef: SeccfileuploaderComponent;

  constructor(
    private router: Router,
    private secccharityService: SecccharityService,
    private sessiontimeoutService : SessiontimeoutService
  ) {}

  softfilereqSubscription: Subscription = new Subscription();

  isValidationError: boolean = false;
  errMessages: Array<string> = new Array<string>();

  fileType_1: Array<string> = ['image','doc','pdf'];
  maxFileSz_1: number = 1024 * 1024;
  maxFileCount_1: number = 3;

  fileType_2: Array<string> = ['doc','image','pdf'];
  maxFileSz_2: number = 1024 * 1024;
  maxFileCount_2: number = 2;

  fileType_3: Array<string> = ['image','pdf'];
  maxFileSz_3: number = 1024 * 1024;
  maxFileCount_3: number = 1;

  ngOnInit() {
    this.sessiontimeoutService.reset();
    this.softFileReqModel = new SoftFileReqModel(); // not needed
  }
  ngAfterViewInit() {
    this.secccharityService.scrollToTopFast();
  }

  public continue() {
    this.softfilereqSubscription = this.secccharityService
      .valSoftFileReq()
      .subscribe((response) => {

        if (!response.isInvalidReq && response.status == 'success') {
            this.saveUploaderQueueState(); // Save file item queue to recreate queue if/when returning to page

            if(this.isFederationCharity()) {
              this.router.navigate(['financialcertsfc']);
            } else {
              this.router.navigate(['admincerts']);
            }
        }

        if (response.invalidReq) {
          // set component validation and err flags
          this.isValidationError = true;
          this.errMessages = response.validationResult.errMessages;
          this.secccharityService.scrollToTop();
        }
      });
  }

  //Save file uploader queues to a map
  saveUploaderQueueState() {

    this.secccharityService.uploaderQueueMap.set('digtlpics',this.digtlpicsRef.uploader.queue);
    this.secccharityService.uploaderQueueMap.set('story',this.storyRef.uploader.queue);
    this.secccharityService.uploaderQueueMap.set('logo',this.logoRef.uploader.queue);
  }

  isFederationCharity() : boolean {

    if(this.secccharityService.appType == 'FC') {
      return true;
    }
    return false;
  }


  ngOnDestroy() {
    if (this.softfilereqSubscription != undefined)
      this.softfilereqSubscription.unsubscribe();
  }
} //end class
