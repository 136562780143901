import { FinCertsModel } from './../models/FinCertsModel';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';

import { SecccharityService } from './../../services/secccharity.service';
import { SessiontimeoutService } from 'src/app/services/sessiontimeout.service';
import { SeccfileuploaderComponent } from './../seccfileuploader/seccfileuploader.component';


@Component({
  selector: 'app-financialcerts',
  templateUrl: './financialcerts.component.html',
  styleUrls: ['./financialcerts.component.css'],
})
export class FinancialcertsComponent implements OnInit {
  constructor(
    private secccharityService: SecccharityService,
    private sessiontimeoutService : SessiontimeoutService,
    private router: Router
  ) {}

  @ViewChild('attachE')
  attachERef: SeccfileuploaderComponent;

  @ViewChild('attachF')
  attachFRef: SeccfileuploaderComponent;

  @ViewChild('attachG')
  attachGRef: SeccfileuploaderComponent;

  isValidationError: boolean = false;
  errMessages: Array<string> = new Array<string>();

  fileType_finCert: Array<string> = ['image','doc','pdf'];
  maxFileCount_finCert: number = 3;
  maxFileSz_finCert: number = 2048 * 1024;

  campaignYear : number;
  finCertsModel: FinCertsModel;
  finCertsSubscription: Subscription = new Subscription();
  campaignYearSubscription: Subscription = new Subscription();
  L_totalExpenses: number = 0;
  L_adminExpensePctg: number = 0;


  ngOnInit() {

    this.sessiontimeoutService.reset();
    this.secccharityService.scrollToTop();
    //If previously saved to service object, copy from there.
    this.finCertsModel = this.secccharityService.finCertsModel;
    if (this.finCertsModel == undefined) {
      console.log('creating new fincerts model');
      this.finCertsModel = new FinCertsModel();
    }
    this.setCampaignYear();
    this.calculateTotalExp(); // for existing apps
  }

  ngAfterViewInit() {
    this.secccharityService.scrollToTopFast();
  }


  public continue() {
    this.isValidationError = false; // initialize

    //save raw state to service object
    this.secccharityService.finCertsModel = this.finCertsModel;
    this.updateFinCert();
  }

  updateFinCert() {
    console.log(this.finCertsModel);
    this.finCertsSubscription = this.secccharityService
      .updateFinCert(this.finCertsModel)
      .subscribe(
        (response) => {
          if (!response.isInvalidReq && response.status == 'success') {

            this.saveUploaderQueueState(); // Save file item queue to recreate queue if/when returning to page
            this.router.navigate(['submit']);
          }
          if (response.invalidReq) {
            // set component validation and err flags
            this.isValidationError = true;
            this.errMessages = response.validationResult.errMessages;
            console.log(this.errMessages);
            this.secccharityService.scrollToTop();
          }

        },
        (err) => {
          console.log(err);
        }
      );
  }

  //Save file uploader queues to a map
  saveUploaderQueueState() {

    this.secccharityService.uploaderQueueMap.set('attachE',this.attachERef.uploader.queue);
    this.secccharityService.uploaderQueueMap.set('attachF',this.attachFRef.uploader.queue);
    this.secccharityService.uploaderQueueMap.set('attachG',this.attachGRef.uploader.queue);
  }

  // Box A + Box B
  // (Mangement & General Expenses) + Fundraising Expenses
  calculateTotalExp() {
    this.L_totalExpenses = 0;
    if (this.finCertsModel.l_A != NaN && this.finCertsModel.l_A != undefined) {
      this.L_totalExpenses += this.finCertsModel.l_A;
    }
    if (this.finCertsModel.l_B != NaN && this.finCertsModel.l_B != undefined) {
      this.L_totalExpenses += this.finCertsModel.l_B;
    }
    this.L_totalExpenses = Math.round(this.L_totalExpenses * 100) / 100;

    this.adminPerc();
  }

  /*
   * Admin & general expenses is what percentage of total revenue
   */

  adminPerc() {
    var mgmtAndGenExp = this.finCertsModel.l_A + this.finCertsModel.l_B;

    var totalRevenue = this.finCertsModel.l_D;

    if (totalRevenue == 0 || isNaN(totalRevenue)) return;

    if (mgmtAndGenExp == 0 || isNaN(mgmtAndGenExp)) return;

    this.L_adminExpensePctg =
      Math.round((mgmtAndGenExp / totalRevenue) * 10000) / 100;
  }

  setCampaignYear() {
    console.log('In set campaign year');
    this.campaignYearSubscription = this.secccharityService.getCampaignYear()
    .subscribe((response) => {

        this.campaignYear = this.secccharityService.campaignYear = parseInt(response as string);
    },
    (err) => {
      console.log('ERROR: ');
      console.log(err);
    });
  }

  ngOnDestroy() {

    if (this.finCertsSubscription != undefined)
      this.finCertsSubscription.unsubscribe();

    if (this.campaignYearSubscription != undefined)
      this.campaignYearSubscription.unsubscribe();

  }
}
