import { Injectable } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';




/*
  'File uploader service is actually only used for 'delete' since
   upload is natively supported in ng2-file-upload component
*/
@Injectable({
  providedIn: 'root'
})
export class SeccfileuploaderService {

  constructor(private http: HttpClient) { }

  public deleteFile(fileName : string, deleteUrl : string) : Observable<any> {
    const setGeneralInfoEndpoint = environment.server_url  + deleteUrl +'?fileName=' + fileName ;
    const bodyString = JSON.stringify(fileName);
    const headers = new Headers({ 'Content-type': 'application/json' });
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    return this.http.get(setGeneralInfoEndpoint, httpOptions);
  }


  public listAttachments(uploader : string) {  //Attachments for (..what ?)

    const listFilesEndpoint =  environment.server_url + 'secconlineapp/admin/listattachments';
    const  params = new HttpParams()
                    .append('uploader',uploader);
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' }),
    };
    return this.http.post(listFilesEndpoint, params, httpOptions);
}

public downloadAttachment(key : string) {

    const downloadAttachmentEndpoint =  environment.server_url + 'secconlineapp/admin/downloadattachment';
    const  params = new HttpParams()
                    .append('key',key);

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/x-www-form-urlencoded'
      }),
      responseType : 'blob' as 'json'
    };
    return this.http.post(downloadAttachmentEndpoint, params, httpOptions);
}



}
