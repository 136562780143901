import { CharityadminService } from './charityadmin.service';
import { SecccharityService } from './secccharity.service';
import { Injectable } from '@angular/core';
import { Router} from '@angular/router';

import { Idle, DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core';
import { Keepalive } from '@ng-idle/keepalive';
import { IdletimeoutdialogComponent } from '../secconlineapp/idletimeoutdialog/idletimeoutdialog.component';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';


/*
  Inject this service into every component (page) and call reset() in ngOnInit().
  From there everything happens automatically;
*/

@Injectable({
  providedIn: 'root',
})
export class SessiontimeoutService {
  //For Idle-Timeout
  idleState = 'Not started.';
  timedOut = false;
  lastPing?: Date = null;

  timeoutDialogRef: MatDialogRef<IdletimeoutdialogComponent, any>;

  //Application Session timeout params
  readonly IDLE_TIMEOUT_WARNING : number = 15 * 60; // seconds
  readonly TIMEOUT : number = 5 * 60;    // no. of seconds in which session expires following warning).



  constructor(
    private router : Router,
    private secccharityService : SecccharityService,
    private charityadminService : CharityadminService,
    private idle: Idle,
    private keepalive: Keepalive,
    public dialog: MatDialog,
  ) {

    idle.setIdle(this.IDLE_TIMEOUT_WARNING);
    idle.setTimeout(this.TIMEOUT);
    idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);       // sets default interrupts, in this case clicks, scrolls, touches to the document

    //idle.onIdleEnd.subscribe(() => this.idleState = 'No longer idle.'); // Not needed

    //TIMED OUT
    idle.onTimeout.subscribe(() => {
      this.idleState = 'Timed out!';
      this.timedOut = true;
      console.log(this.idleState);

      //If Admin, just log out.
      if(this.charityadminService.isAdmin) {
          this.adminLogout();
      }

      this.secccharityService.startNewApplication()
                             .subscribe((response) => {     // TODO : nested .subscribe() not ideal.  Use forkjoin() instead
                                  console.log('Clearing session /starting new application..');
                                  this.timeoutDialogRef.close();
                                  this.router.navigate(['instructions']);
                              },
                              (err) =>{
                                console.log(err);
                              });
      });


    // IDLE THRESHOLD REACHED
    idle.onIdleStart.subscribe(() => {
                                  //this.idleState = 'You\'ve gone idle!'
                                  //console.log(this.idleState);
                                  this.showTimeoutAlertModal();
                                });



    idle.onTimeoutWarning.subscribe((countdown) =>  {
                                        this.idleState = 'You will time out in ' + this.TIMEOUT + ' seconds!' ;
                                      });

    // sets the ping interval to 15 seconds
    keepalive.interval(15);
    keepalive.onPing.subscribe(() => {
                                this.lastPing = new Date();
                                //console.log('Staying alive..' + this.lastPing);
                              });

    // this.reset(); // Call in individual components' ngOnInit()

  } // end constructor


   //Reset idle timer (at start of every page). Call in each component's ngOnInit()
  public reset() {

    this.idle.watch();
    this.idleState = 'Started.';
    this.timedOut = false;
    //console.log(this.idleState);
  }


  // Show Timeout Alert
  showTimeoutAlertModal() {

    this.timeoutDialogRef = this.dialog.open(IdletimeoutdialogComponent, {
      width: '300px',
      height: 'auto',

    });

  }

  //Log out Admin
  adminLogout(){
    window.location.href = 'admin/auth/dologout';
  }



}
