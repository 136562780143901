import { SecccharityService } from './../../services/secccharity.service';
import { SessiontimeoutService } from 'src/app/services/sessiontimeout.service';
import { Component, OnInit } from '@angular/core';
import { GeneralInfoModel } from '../models/GeneralInfoModel';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-generalinfo',
  templateUrl: './generalinfo.component.html',
  styleUrls: ['./generalinfo.component.css'],
})
export class GeneralinfoComponent implements OnInit {
  public generalInfoModel: GeneralInfoModel;

  private SVC_CODES_ARR_SZ = 25;
  private CTY_CODES_ARR_SZ = 15;
  private SPK_CTY_CODES_ARR_SZ = 16;

  isValidationError: boolean = false;
  errMessages: Array<string> = new Array<string>();
  isShowFedName: boolean;
  federations;
  serviceCodeOptions: Array<any> = new Array<any>(); //list of svc code options from db
  countyOptions: Array<any> = new Array<any>(); //list of county options from db
  speakCountyOptions: Array<any> = new Array<any>(); //list of county options from db

  constructor(
    private secccharityService: SecccharityService,
    private sessiontimeoutService : SessiontimeoutService,
    private router: Router
  ) {}

  private generalInfoSubscription: Subscription = new Subscription();
  private federationsSubscription: Subscription = new Subscription();
  private serviceCodesSubscription: Subscription = new Subscription();
  private countySubscription: Subscription = new Subscription();
  private speakCountySubscription: Subscription = new Subscription();

  showAllFields: boolean;

  ngOnInit() {

    this.sessiontimeoutService.reset();
    //If previously saved to service object, copy from there.
    this.generalInfoModel = this.secccharityService.generalInfoModel;
    if (this.generalInfoModel == undefined) {
      this.generalInfoModel = new GeneralInfoModel();
    }
    this.generalInfoModel.appType = this.secccharityService.appType; // update AppType (only reqd to validate General Info)

    this.showAllFields = false;
    this.checkFederationCharity();
    this.setServiceCodes();
    this.setCountyOptionsForServices();
    this.setSpeakCountyOptionsForServices();
  }
  ngAfterViewInit() {
    this.secccharityService.scrollToTopFast();
  }

  //Service Codes
  public setServiceCodes() {
    this.serviceCodesSubscription = this.secccharityService
      .getServiceCodes()
      .subscribe((result) => {
        //Sort result ( array of objects) into 2D mx4 array for page display
        let resultArr = Object.values(result);
        for (var i = 0; i < resultArr.length; i = i + 4) {
          this.serviceCodeOptions.push(resultArr.slice(i, i + 4));
        }

      });
  }

  //Counties (#5 on GeneralInfo page)
  setCountyOptionsForServices() {
    this.countySubscription = this.secccharityService
      .getCounties(true)
      .subscribe((result) => {
        //Sort result ( array of objects) into 2D mx4 array for page display
        let resultArr = Object.values(result);
        for (var i = 0; i < resultArr.length; i = i + 4) {
          this.countyOptions.push(resultArr.slice(i, i + 4));
        }
      });
  }

  //Speak Counties (#7 on GeneralInfo page)
  setSpeakCountyOptionsForServices() {
    this.speakCountySubscription = this.secccharityService
      .getCounties(false)
      .subscribe((result) => {
        //Sort result ( array of objects) into 2D mx4 array for page display
        let resultArr = Object.values(result);
        for (var i = 0; i < resultArr.length; i = i + 4) {
          this.speakCountyOptions.push(resultArr.slice(i, i + 4));
        }
      });
  }

  //CONTINUE
  public continue() {
    this.isValidationError = false;
    //save raw state to service object
    this.secccharityService.generalInfoModel = this.generalInfoModel;

    this.generalInfoSubscription = this.secccharityService
      .setGeneralInfo(this.generalInfoModel)
      .subscribe(
        (response) => {
          if (!response.isInvalidReq && response.status == 'success') {
                this.router.navigate(['softfilereq']);
          }
          if (response.invalidReq) {
            // set component validation and err flags
            this.isValidationError = true;
            this.errMessages = response.validationResult.errMessages;
            this.secccharityService.scrollToTop();
          }
        },
        (err) => {
          console.log(err);
        }
      );
  } // end continue()

  setServiceCodeCheckBox(event: any, value: any) {
    if (event.target.checked) this.generalInfoModel.serviceCodes.push(value);
    else
      this.generalInfoModel.serviceCodes =
        this.generalInfoModel.serviceCodes.filter((val) => val != value);
  }

  setCountyCheckBox(event: any, value: any) {
    if (event.target.checked) this.generalInfoModel.countyCodes.push(value);
    else
      this.generalInfoModel.countyCodes =
        this.generalInfoModel.countyCodes.filter((val) => val != value);
  }
  setSpeakCountyCheckBox(event: any, value: any) {
    if (event.target.checked) this.generalInfoModel.speakCountyCodes.push(value);
    else
      this.generalInfoModel.speakCountyCodes =
        this.generalInfoModel.speakCountyCodes.filter((val) => val != value);
  }


  //If returning from another page check if options were previously
  //selected. Checked respective arrays containing previously
  //selected values.

  doesServiceCodesContain(code:string):boolean {
    if(this.secccharityService.generalInfoModel.serviceCodes == undefined) {
      return false;
    }
    if(this.secccharityService.generalInfoModel.serviceCodes.indexOf(code) > -1 ) {
      return true;
    }
    return false;
  }

  doesCountyCodesContain(code:string):boolean {

    if(this.secccharityService.generalInfoModel.countyCodes == undefined) {
      return false;
    }
    if(this.secccharityService.generalInfoModel.countyCodes.indexOf(code) > -1 ) {
      return true;
    }
    return false;
  }

  doesSpeakCountyCodesContain(code:string):boolean {
    if(this.secccharityService.generalInfoModel.speakCountyCodes == undefined) {
      return false;
    }
    if(this.secccharityService.generalInfoModel.speakCountyCodes.indexOf(code) > -1 ) {
      return true;
    }
    return false;
  }


  //=================PRIVATES====================//

  private checkFederationCharity() {
    if (this.secccharityService.appType == 'FC') {
      this.isShowFedName = true;
      this.federationsSubscription = this.secccharityService
        .getFederations()
        .subscribe(
          (result) => {
            this.federations = result;
          },
          (err) => {}
        );
    }
  }

  private convertBoolToStringCountyCodes(
    generalInfoModel: GeneralInfoModel
  ): GeneralInfoModel {
    //Deep copy (clone) object
    let updatedGeneralInfoModel: GeneralInfoModel = JSON.parse(
      JSON.stringify(generalInfoModel)
    );

    let countyCodesString: string[] = new Array();

    for (let i = 0; i < this.CTY_CODES_ARR_SZ; i++) {
      if (generalInfoModel.countyCodes[i]) {
        countyCodesString.push(i.toString());
      }
    }

    updatedGeneralInfoModel.countyCodes = countyCodesString;
    return updatedGeneralInfoModel;
  }

  private convertBoolToStringSpeakCountyCodes(
    generalInfoModel: GeneralInfoModel
  ): GeneralInfoModel {
    //Deep copy (clone) object
    let updatedGeneralInfoModel: GeneralInfoModel = JSON.parse(
      JSON.stringify(generalInfoModel)
    );

    let speakCountyCodesString: string[] = new Array();

    for (let i = 0; i < this.SPK_CTY_CODES_ARR_SZ; i++) {
      if (generalInfoModel.speakCountyCodes[i]) {
        speakCountyCodesString.push(i.toString());
      }
    }

    updatedGeneralInfoModel.speakCountyCodes = speakCountyCodesString;
    return updatedGeneralInfoModel;
  }

  // If Section 1. (Name & Contact info) is filled,
  // show remaining fields of the form

  public isNameContactInfoFilled(): boolean {

    if (this.generalInfoModel.title.trim().length === 0) {
      return false;
    }
    if (this.generalInfoModel.legalName.trim().length === 0) {
      return false;
    }
    if (this.generalInfoModel.address.trim().length === 0) {
      return false;
    }
    if (this.generalInfoModel.city.trim().length === 0) {
      return false;
    }
    if (this.generalInfoModel.state.trim().length === 0) {
      return false;
    }
    if (this.generalInfoModel.zip.trim().length === 0) {
      return false;
    }
    if (this.generalInfoModel.contactName.trim().length === 0) {
      return false;
    }
    if (this.generalInfoModel.contactEmail.trim().length === 0) {
      return false;
    }
    if (this.generalInfoModel.contactPhone.trim().length === 0) {
      return false;
    }
    if (this.generalInfoModel.email.trim().length === 0) {
      return false;
    }
    if (this.generalInfoModel.phone.trim().length === 0) {
      return false;
    }
    return true;
  }



  ngOnDestroy() {
    if (this.generalInfoSubscription != undefined)
      this.generalInfoSubscription.unsubscribe();
    if (this.federationsSubscription != undefined)
      this.federationsSubscription.unsubscribe();
    if (this.serviceCodesSubscription != undefined)
      this.serviceCodesSubscription.unsubscribe();
    if (this.countySubscription != undefined)
      this.countySubscription.unsubscribe();
    if (this.speakCountySubscription != undefined)
      this.speakCountySubscription.unsubscribe();
  }
}
