import { CharityadminService } from './../../services/charityadmin.service';
import { Component, OnInit,ChangeDetectorRef } from '@angular/core';
import { formatDate } from '@angular/common';
import { SubmitModel } from '../../secconlineapp/models/SubmitModel';
import { SecccharityService } from '../../services/secccharity.service';
import { SessiontimeoutService } from 'src/app/services/sessiontimeout.service';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';

declare var grecaptcha: any;

@Component({
  selector: 'app-submit',
  templateUrl: './submit.component.html',
  styleUrls: ['./submit.component.css'],
})
export class SubmitComponent implements OnInit {


  constructor(
    public secccharityService: SecccharityService,
    public charityadminService : CharityadminService,
    private sessiontimeoutService : SessiontimeoutService,
    private router: Router,
    private cd: ChangeDetectorRef
  ) {
    // reCapcha callbacks. (bind component functions to a global scope that reCaptcha has access to)
    // Note - without these bindings reCaptcha will not be able to 'see' the two callbacks defined
    // in this component
    window['onSuccess'] = this.onSuccess.bind(this);
    window['onFail'] = this.onFail.bind(this);
  }

  captchaResponse : string = '';
  isCaptchaVerified : boolean ;
  isShowCaptchaError : boolean ;

  isValidationError: boolean = false;
  errMessages: Array<string> = new Array<string>();

  submitModel: SubmitModel;
  submitSubsciption: Subscription = new Subscription();
  isPaymentRequired: String;

  ngOnInit() {

    this.sessiontimeoutService.reset();
    this.isCaptchaVerified = false;
    this.isShowCaptchaError = false;
    this.isPaymentRequired = this.secccharityService.generalInfoModel.logo; // 'true' / 'false'
   // this.renderRecaptcha(); //DISABLE RECAPTCHA

    this.submitModel = this.secccharityService.submitModel;
    if (this.submitModel == undefined) {

      this.submitModel = new SubmitModel();
    }
  }
  ngAfterViewInit() {
    this.secccharityService.scrollToTopFast();
  }

  submit() {

    //PROD HOTFIX. Disable Captcha validation
    this.isCaptchaVerified = true;
    //

    if(! this.isCaptchaVerified) {
      this.isShowCaptchaError = true;
      return;
    }

    var spinner = this.secccharityService.startSpinner('spinarea-secc');
    this.isValidationError = false; // initialize

    this.secccharityService.submitModel = this.submitModel;

    this.submitSubsciption = this.secccharityService
      .submitApplication(this.submitModel)
      .subscribe(
        (response) => {
          console.log(response);
          this.secccharityService.stopSpinner(spinner);
          if (!response.isInvalidReq && response.status == 'success') {

            if(this.isPaymentRequired=='true'){
              window.location.href = response.redirectUrl;
            } else {
              this.router.navigate(['thankyou']);
            }
          }

          if (response.invalidReq) {
            // set component validation and err flags
            this.isValidationError = true;
            this.errMessages = response.validationResult.errMessages;
            console.log(this.errMessages);
            this.secccharityService.scrollToTop();
          }

        },
        (err) => {
          console.log('ERROR: ');
          console.log(err);
          this.isValidationError = true; //although not actually a 'validation' error
          this.errMessages = ['An internal error occurred'];
          this.secccharityService.stopSpinner(spinner);
          this.secccharityService.scrollToTop();
        }
      );
  }

  // Getcurrent date / time
  getCurrentDate() {
    return formatDate(new Date(), 'MM/dd/yyyy', 'en');
  }

  ngOnDestroy() {
    if (this.submitSubsciption != undefined)
          this.submitSubsciption.unsubscribe();
  }


  //reCaptcha
  renderRecaptcha() {
    grecaptcha.render('recaptchaHere', {
      'sitekey' : '6Lfqz3kjAAAAAJuGNJhPGHWvu3Fvq3RXQ-B-vm3n',
      'callback' : 'onSuccess',
      'expired-callback' : 'onFail'
    });
  }

  onSuccess(response) {

    if(response.length===0){    // will never happen
      this.isCaptchaVerified = false;

    }else {

      this.submitModel.captchaResponse = response;
      this.isCaptchaVerified= true;
      this.isShowCaptchaError = false;
      this.cd.detectChanges();   // Since isShowCaptchaError is updated asynchronously with a callback and
                                 // the change is not automatically detected, the captcha error message stays
                                 // on screen even after a new captcha value is obtained from Google. That
                                 // should not be the case. The error should go away once there is a new value.
                                 // A change detect is triggered manually here to make sure all visibility
                                 // conditions are updated on the screen.
    }
  }


  onFail() {
    this.isCaptchaVerified= false;
  }
}
