<div>
  <!--SUBMIT -->

  <tbody>
    <tr>
      <td>
        <table
          border="0"
          cellspacing="0"
          style="background-color: White"
          cellpadding="5"
          width="800px"
          align="center"
        >
        <tbody>
          <tr>
            <td width="50%">
              <div
                id="ctl00_ValidationSummary1"
                style="color: Red"
                [hidden]="!isValidationError"
              >
                <h4>Please correct the following errors</h4>
                <ul>
                  <li *ngFor="let error of errMessages">
                    {{ error }}
                  </li>
                </ul>
              </div>
            </td>
            <td width="50%"></td>
          </tr>
        </tbody>
        </table>

        <div id="ctl00_pageContent_pnlContent">
          <h3>Signature by authorized official</h3>
          <table width="100%">
            <tbody>
              <tr>
                <td td="" colspan="4">
                  I, AM THE DULY APPOINTED REPRESENTATIVE OF THE ABOVE NAMED
                  ORGANIZATION. By my signature below, I certify and affirm all
                  statements and information enclosed in this application are
                  true and correct.
                </td>
              </tr>
              <tr>
                <td class="label">
                  <span id="ctl00_pageContent_txtSignName_uclbl">NAME</span>:
                </td>
                <td id="ctl00_pageContent_txtSignName_tdTxt">
                  <input
                    name="ctl00$pageContent$txtSignName$uctxt"
                    type="text"
                    [(ngModel)]="submitModel.submitterName"
                    maxlength="50"
                    id="ctl00_pageContent_txtSignName_uctxt"
                    style="width: 170px"
                  /><span
                    id="ctl00_pageContent_txtSignName_ucrev"
                    style="color: Red; display: none"
                  ></span>
                  <span
                    id="ctl00_pageContent_txtSignName_ucrfv"
                    style="color: Red; display: none"
                  ></span>
                </td>

                <td>
                  <span id="ctl00_pageContent_lblDateText">DATE</span>
                </td>
                <td>
                  <span
                    id="ctl00_pageContent_lblDateValue"
                    style="font-weight: bold"
                    >{{ getCurrentDate() }}</span
                  >
                </td>
              </tr>

              <tr>
                <td class="label">
                  <span id="ctl00_pageContent_txtSignTitle_uclbl"
                    >OFFICIAL JOB TITLE</span
                  >:
                </td>
                <td id="ctl00_pageContent_txtSignTitle_tdTxt" colspan="2">
                  <input
                    name="ctl00$pageContent$txtSignTitle$uctxt"
                    type="text"
                    [(ngModel)]="submitModel.submitterJobTitle"
                    maxlength="255"
                    id="ctl00_pageContent_txtSignTitle_uctxt"
                    style="width: 400px"
                  /><span
                    id="ctl00_pageContent_txtSignTitle_ucrev"
                    style="color: Red; display: none"
                  ></span>
                  <span
                    id="ctl00_pageContent_txtSignTitle_ucrfv"
                    style="color: Red; display: none"
                  ></span>
                </td>
              </tr>
              <div id="spinarea-secc"></div>

          <!--  DISABLE CAPTCHA (Production hotfix)
              <tr align="left">
                <td>
                  <div id="recaptchaHere"></div>

                  <div
                    class="g-recaptcha"
                    data-sitekey="6Lfqz3kjAAAAAJuGNJhPGHWvu3Fvq3RXQ-B-vm3n"
                    data-callback="onSuccess"
                    data-expired-callback="onFail"
                  ></div>

                  <br />
                </td>
              </tr>

              <tr>
                <td>
                  <div
                    class="captchaError"
                    id="captchaError"
                    *ngIf="isShowCaptchaError"
                  >
                    Please verify that you are not a robot.
                  </div>
                  <div id="spinarea-secc"></div>
                </td>
              </tr>
            -->
              <tr>
                <td colspan="4">&nbsp;</td>
              </tr>
              <tr>
                <td colspan="4">
                  All the information is required to be submitted. If any
                  information is unavailable please email Linda Stiles at
                  <a href="mailto:Linda.Stiles@azdoa.gov"
                    >Linda.Stiles@azdoa.gov</a
                  >
                </td>
              </tr>
              <tr>
                <td colspan="4">
                  <hr />
                  &nbsp;
                </td>
              </tr>
              <!--*ngIf="!this.charityadminService.isAdmin"-->
              <tr *ngIf="!this.charityadminService.isAdmin">
                <td colspan="4" style="text-align: center">
                  <input
                    type="image"
                    width="100"
                    name="ctl00$pageContent$btnNext"
                    id="ctl00_pageContent_btnNext"
                    src="assets/img/submit-button.jpg"
                    (click)="submit()"
                    style="border-width: 0px"
                  />
                </td>
              </tr>
              <tr id="paypalRedirectMesg" *ngIf="isPaymentRequired=='true'">
                <td colspan="4">
                    <span style="color: Red">Please Note: You will be redirected to PayPal after
                        you click the Submit button for the $25.00 Logo fee. <p><b>DO NOT </b>close browser window after payment is complete. You will be redirected back to the SECC website for payment acknowledgement.</span>
                </td>
              </tr>
              <tr>
                <td align="center" colspan="4">
                  <span
                    id="ctl00_pageContent_lblError"
                    style="color: Red"
                  ></span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </td>
    </tr>
  </tbody>
</div>
