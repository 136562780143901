import { Component, OnInit } from '@angular/core';
import { SecccharityService } from '../../services/secccharity.service';
import { SessiontimeoutService } from 'src/app/services/sessiontimeout.service';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-apptype',
  templateUrl: './apptype.component.html',
  styleUrls: ['./apptype.component.css'],
})
export class ApptypeComponent implements OnInit {

  constructor(
        private secccharityService: SecccharityService,
        private sessiontimeoutService : SessiontimeoutService,
        private router: Router
  ) {}


  private appTypeSubscription: Subscription = new Subscription();
  appType: string = '';
  isValidationError: boolean = false;
  errMessages: Array<string> = new Array<string>();


  ngOnInit() {

    this.sessiontimeoutService.reset();

    //If previously saved to service object, copy from there.
    if(this.secccharityService.appType != undefined && this.secccharityService.appType != '') {
        this.appType = this.secccharityService.appType;
    } else {
      this.appType = '';
    }
  }
  ngAfterViewInit() {
    this.secccharityService.scrollToTopFast();
  }



  public continue() {

    this.isValidationError = false;
    this.secccharityService.appType = this.appType;

    this.appTypeSubscription = this.secccharityService
      .setAppType(this.appType)
      .subscribe(
        (response) => {
          if (!response.isInvalidReq && response.status == 'success') {
            this.router.navigate(['generalinfo']);
          }
          if (response.invalidReq) {
            // set component validation and err flags
            this.isValidationError = true;
            this.errMessages = response.validationResult.errMessages;
            console.log(response);
            this.secccharityService.scrollToTop();
          }
        },
        (err) => {
          console.log(err);
        }
      );
  }


  ngOnDestroy() {
    if (this.appTypeSubscription != undefined)
      this.appTypeSubscription.unsubscribe();
  }
}
