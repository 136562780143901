export class GeneralInfoModel {

  public constructor() {


  }

  public title  : string = "";
  public legalName  : string = "";
  public federationId : string ="";
  public address  : string = "";
  public city  : string = "";
  public state  : string = "";
  public zip  : string = "";
  public contactName  : string = "";
  public contactEmail  : string = "";
  public contactPhone  : string = "";
  public phone  : string = "";
  public email  : string = "";
  public url  : string = "";
  public serviceCodes : string[] = new Array<string>();
  public percentage  : string = "";
  public narrative  : string = "";
  public countyCodes : string[] = new Array<string>();
  public county : boolean;
  public logo  : string = "";
  public speakCountyCodes  : string[] = new Array<string>();
  public locations  : string = "";

  public speakerBureau  : string = "";
  public taxID  : string = "";

  public appType : string =""; //required for validation only

}
