import { SecccharityService } from './../../services/secccharity.service';
import { CharityadminService } from './../../services/charityadmin.service';
import { SeccfileuploaderService } from './seccfileuploader.service';
import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';
import { Subscription,BehaviorSubject, Observable } from 'rxjs';
import { FileUploader, FileItem } from 'ng2-file-upload';
import { HttpResponse } from '@angular/common/http';



//  A child component
@Component({
  selector: 'app-seccfileuploader',
  templateUrl: './seccfileuploader.component.html',
  styleUrls: ['./seccfileuploader.component.css'],
})
export class SeccfileuploaderComponent implements OnInit {

  constructor(private seccfileuploaderService: SeccfileuploaderService,
              public secccharityService : SecccharityService,
              public charityadminService : CharityadminService
              ) {}

  @Input() name; // Name of this instance of file upload component (ie what this group of files are being uploaded for)
  @Input() uploadUrl; // Api endpoint to upload/delete from AWS S3
  @Input() deleteUrl; // main functional token from the delete API url. eg. 'deleteClientOrServicePic' in "secconlineapp/<deleteClientOrServicePic></deleteClientOrServicePic>?fileName=""
  @Input() fileType;
  @Input() maxFileSize: number;
  @Input() allowMultiple: boolean;
  @Input() maxFileCount: number;
  //@Input() mimeType;

  @ViewChild('uploadFile')
  seccUploadFileRef: ElementRef;  //HTML element ref for the area that displays uploaded file name

  uploader: FileUploader;
  hasBaseDropZoneOver: boolean;
  hasAnotherDropZoneOver: boolean;
  response: string;

  isUploadComplete: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  //Admin
  attachments : Array<any> = [];

  fileRemoveSubscription: Subscription = new Subscription();
  fileUploadStatusSubscription: Subscription = new Subscription();
  attachmentsSubscription : Subscription = new Subscription();
  downloadAttachmentSubscription : Subscription = new Subscription();

  ngOnInit() {

    this.uploader = new FileUploader({
      url: this.uploadUrl, // Only remote upload natively supported in ng2-file-upload. Remote delete requires a modification
      disableMultipart: false,
      maxFileSize: this.maxFileSize,
      allowedFileType:this.fileType,
      queueLimit:this.maxFileCount,
     // allowedMimeType:['image/jpeg'],
      formatDataFunctionIsAsync: false,
      formatDataFunction: async (item) => {
        return new Promise((resolve, reject) => {
          resolve({
            name: item._file.name,
            length: item._file.size,
            contentType: item._file.type,
            date: new Date(),
          });
        });
      },
    });

    this.loadUploaderQueueFromMap();   //Load file uploader queue state from map if previously set
    //List attachments for this uploader
    this.listAttachments(this.name);
    this.hasBaseDropZoneOver = false;
    this.hasAnotherDropZoneOver = false;
    this.response = '';
    this.uploader.response.subscribe((res) => (this.response = res));
  }

  loadUploaderQueueFromMap() {

    if(this.secccharityService.uploaderQueueMap.get(this.name) != undefined) {
      this.uploader.queue = this.secccharityService.uploaderQueueMap.get(this.name);
    }
  }


  uploadToServer(item: FileItem) {

    var spinner = this.secccharityService.startSpinner('spinarea-secc');
    item.upload();

    setTimeout(()=>{
      this.secccharityService.stopSpinner(spinner);
      this.listAttachments(this.name); //refresh attachment list (for admin view) //Doesn't work !
    },750);   //TODO sync stopSpinner with item.isSuccess

  }



  /* Calling an API to delete file from remote location is currently not supported
   * in ng2-file-upload. Below is a wrapper around the component provided
   * remove() which calls API to delete remote file.
   *
   * deleteEndpoint is the main token from the backend delete API url
   * eg 'deleteClientOrServicePic' for the softfilereq page.
   */
  removeFromServer(item: FileItem) {

    var spinner = this.secccharityService.startSpinner('spinarea-secc');

    this.fileRemoveSubscription = this.seccfileuploaderService
      .deleteFile(item.file.name, this.deleteUrl)
      .subscribe(
        (results) => {
          console.log('delete url:');
          console.log(this.deleteUrl);
          this.seccUploadFileRef.nativeElement.value = ''; // clear file name from select area
          item.remove();
          this.listAttachments(this.name); // refresh attachment list (for admin view) // Doesn't work !
          this.secccharityService.stopSpinner(spinner);
        },
        (err) => {
          this.seccUploadFileRef.nativeElement.value = ''; // clear file name from select area
          item.remove();
          this.secccharityService.stopSpinner(spinner);
        } // remove even if error
      );
  }

  removeAllFromServer() {
    let files: Array<FileItem> = this.uploader.queue;
    files.forEach((item) => {
      this.removeFromServer(item);
    });
    //this.uploader.clearQueue();// Not necessary since removed one at a time during removeFromServer()
  }


  //List existing attachments for an uploader
  listAttachments(uploader : string) {

    this.attachmentsSubscription = this.seccfileuploaderService.listAttachments(uploader)
                                   .subscribe((response) => {
                                      this.attachments = response as Array<any>;
                                      console.log(this.attachments);
                                   },
                                   (err) => {
                                    console.log(err);
                                   }
                                   );
  }



  downloadAttachment(filename : string, key : string) {

    this.downloadAttachmentSubscription = this.seccfileuploaderService.downloadAttachment(key)
                                          .subscribe((resultBlob: Blob) => {


                                            var downloadURL = URL.createObjectURL(resultBlob);
                                            const anchor = document.createElement("a");
                                            anchor.download = filename;
                                            anchor.href = downloadURL;
                                            anchor.click();

                                          },
                                          (err) => {
                                            console.log(err);
                                          }
                                          );

  }

  ngOnDestroy() {
    if(this.fileRemoveSubscription != undefined)
                this.fileRemoveSubscription.unsubscribe();
    if(this.fileUploadStatusSubscription != undefined)
                this.fileUploadStatusSubscription.unsubscribe();
    if(this.downloadAttachmentSubscription != undefined)
                this.downloadAttachmentSubscription.unsubscribe();
    if(this.attachmentsSubscription != undefined)
                this.attachmentsSubscription.unsubscribe();
  }
}
