<div>
  <tbody>
    <tr>
      <td>
        <table
          border="0"
          cellspacing="0"
          style="background-color: White"
          cellpadding="5"
          width="800px"
          align="center"
        >
          <tbody>
            <tr>
              <td>
                <div
                  id="ctl00_ValidationSummary1"
                  style="color: Red"
                  [hidden]="!isValidationError"
                >
                  <h4>Please correct the following errors</h4>
                  <ul>
                    <li *ngFor="let error of errMessages">
                      {{ error }}
                    </li>
                  </ul>
                </div>
              </td>
            </tr>
          </tbody>
        </table>

        <form>
          <h3>1. Type Of Application:</h3>
          <table width="100%">
            <tbody>
              <tr>
                <td style="background-color: Gray; color: White">
                  <span id="ctl00_pageContent_ddlAppType_uclbl"
                    >ApplicationType</span
                  >:
                </td>
                <td>
                  <select
                    name="applicationType"
                    name="applicationType"
                    id="applicationType"
                    [(ngModel)]="appType"
                  >
                    <option value="" disabled selected>select</option>
                    <option value="F">Federation</option>
                    <option value="FC">Federation Charity</option>
                    <option value="IC">Independent Charity</option>
                  </select>
                  <span
                    id="ctl00_pageContent_ddlAppType_ucrfv"
                    style="color: Red; display: none"
                  ></span>
                </td>
              </tr>
              <tr>
                <td colspan="2">
                  <hr />
                  &nbsp;
                </td>
              </tr>
              <tr>
                <td align="right" colspan="2">
                  <input
                    type="image"
                    width="100"
                    name="setAppTypeBtn"
                    id="setAppTypeBtn"
                    src="assets/img/continue-button.jpg"
                    (click)="continue()"
                    style="border-width: 0px"
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </form>
      </td>
    </tr>
  </tbody>
</div>
