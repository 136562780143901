import { CharityadminService } from './services/charityadmin.service';
import { SecccharityService } from './services/secccharity.service';
import { SubmitComponent } from 'src/app/secconlineapp/submit/submit.component';
import { FinancialcertsComponent } from 'src/app/secconlineapp/financialcerts/financialcerts.component';
import { FinancialcertsfcComponent } from './secconlineapp/financialcertsfc/financialcertsfc.component';
import { AdmincertsComponent } from 'src/app/secconlineapp/admincerts/admincerts.component';
import { SoftfilereqComponent } from 'src/app/secconlineapp/softfilereq/softfilereq.component';
import { GeneralinfoComponent } from 'src/app/secconlineapp/generalinfo/generalinfo.component';
import { ApptypeComponent } from 'src/app/secconlineapp/apptype/apptype.component';
import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent {
  title = 'appdev-app-secc-ui';

  // Refactoring exerise :  Refactor Banner UI and logic into its own component and display within AppComponent

  readonly ACTIVE_TAB_BG = '#1C5E55'; // element on the html banner showing page the user is on
  readonly INACTIVE_TAB_BG = '#E3EAEB';
  readonly MOUSEOVER_TAB_BG = '#696969';
  readonly ACTIVE_TAB_FONT = '#FFFFFF';
  readonly INACTIVE_TAB_FONT = '#000000';

  routerEventsSubscription: Subscription = new Subscription();
  startNewAppSubscription: Subscription = new Subscription();
  updateApplicationSubscription: Subscription = new Subscription();
  observeApplicationStatusSubscription: Subscription = new Subscription();

  activeComponentId: string;
  applicationStatus : string;



  allComponentIds: Array<string> = [
    'generalinfo',
    'softfilereq',
    'admincerts',
    'financialcerts',
    'submit',
  ]; //ordered array (for link enabling/ disabling)

  constructor(
    private router: Router,
    public secccharityService: SecccharityService,
    public charityadminService : CharityadminService
  ) {

      this.observeApplicationStatusSubscription =
                      this.charityadminService.applicationStatusChange.subscribe((status) => {
                          this.updateAdminBanner(status);
                      })

  }

  onActivate(event) {
    this.activeComponentId = this.getActiveComponentId(event); // only place updated
    this.updateBanner();
  }

  updateBanner() {
    this.allComponentIds.forEach((e) => {
      var tabElement = document.getElementById(e);
      if (e === this.activeComponentId) {
        tabElement!.style.backgroundColor = this.ACTIVE_TAB_BG;
        tabElement!.style.color = this.ACTIVE_TAB_FONT;
      } else {
        tabElement!.style.backgroundColor = this.INACTIVE_TAB_BG;
        tabElement!.style.color = this.INACTIVE_TAB_FONT;
      }
    });

    this.setCursorTypes();
  }

  navigate(route) {
    if (this.isRouteNavigable(route)) {
      this.router.navigate([route]);
    }
  }

  //Pages that are navigable will have pointer ('hand') cursor.
  private setCursorTypes() {
    for (var i = 0; i < this.allComponentIds.length; i++) {
      var tabElement = document.getElementById(this.allComponentIds[i]);
      var idxActiveComponentId: number = this.allComponentIds.indexOf(
        this.activeComponentId
      ); //Index of current component in same array

      if (i < idxActiveComponentId) {
        tabElement!.style.cursor = 'pointer';
      } else {
        tabElement!.style.cursor = 'text';
      }
    }
  }

  private getActiveComponentId(component) {
    if (component instanceof ApptypeComponent) {
      return 'apptype';
    }
    if (component instanceof GeneralinfoComponent) {
      return 'generalinfo';
    }
    if (component instanceof SoftfilereqComponent) {
      return 'softfilereq';
    }
    if (component instanceof AdmincertsComponent) {
      return 'admincerts';
    }
    if (component instanceof FinancialcertsComponent) {
      return 'financialcerts';
    }
    if (component instanceof FinancialcertsfcComponent) {
      return 'financialcerts';
    }
    if (component instanceof SubmitComponent) {
      return 'submit';
    }
    return '';
  }


  isFC() : boolean {
    return this.secccharityService.appType == 'FC'
  }



  isRouteNavigable(route: string): boolean {


    var idxTargetComponentId: number = this.allComponentIds.indexOf(route); //Index of the route component name in the allComponentIds array
    var idxActiveComponentId: number = this.allComponentIds.indexOf(this.activeComponentId); //Index of current component in same array

    //Disallow navigation to any route that comes after current route (do nothing)
    //In other words user can only go to a page by clicking link that the user has
    //previously visited (ie filled out)
    if (idxTargetComponentId > idxActiveComponentId - 1) {
      return false;
    }
    return true;
  }

  // highlight if route navigable
  onMouseEnter(compId) {
    if (this.isRouteNavigable(compId)) {
      var tabElement = document.getElementById(compId);

      tabElement!.style.backgroundColor = this.MOUSEOVER_TAB_BG;
      if (compId != this.activeComponentId) {
        tabElement!.style.color = this.ACTIVE_TAB_FONT;
      }
    }
  }

  onMouseLeave(compId) {
    //compId value same as route's

    if (this.isRouteNavigable(compId)) {
      var tabElement = document.getElementById(compId);

      if (compId === this.activeComponentId) {
        tabElement!.style.backgroundColor = this.ACTIVE_TAB_BG;
      } else {
        tabElement!.style.backgroundColor = this.INACTIVE_TAB_BG;
        tabElement!.style.color = this.INACTIVE_TAB_FONT;
      }
    }
  }

  //On button click to change application status
  updateNonProfitStatus(status : string) {

    this.updateApplicationSubscription = this.charityadminService.updateNonProfitStatus(status)
                                             .subscribe((res) => {
                                                if(res=="success") {
                                                 // this.updateAdminBanner(status);
                                                 this.charityadminService.applicationStatus = status;
                                                 this.charityadminService.showApplicationStatus();
                                                }
                                                console.log(res);
                                             },
                                             (err) => {
                                             // this.updateAdminBanner(status);  //Remove
                                              console.log(err);
                                             });



  }


  private updateAdminBanner(status : string) {

    const statusMap = new Map([
      ["A","APPROVED"],
      ["P","PENDING"],
      ["PL","PROVISIONAL"],
      ["D","DECLINED"],
      ["R","REVIEWED"],
      ["INC","INCLUDED"]
    ]);

    const colorMap = new Map([
      ["A","rgb(6, 83, 32)"],
      ["P","rgb(255, 255, 255)"],
      ["PL","rgb(243, 247, 8)"],
      ["D","rgb(202, 29, 46)"],
      ["R","rgb(30, 43, 158)"],
      ["INC","rgb(127, 1, 127)"]
    ]);

    this.applicationStatus = statusMap.get(status);
    console.log(document.getElementById("applicationStatusBand"));
    document.getElementById("applicationStatusBand").style.backgroundColor= colorMap.get(status);   //applicationStatusText
    if(status=='PL' || status=='P') {
      document.getElementById("applicationStatusText").style.color= "black";
    } else {
      document.getElementById("applicationStatusText").style.color= "white";
    }

  }



  ngOnDestroy() {

    if (this.routerEventsSubscription != undefined) {
      this.routerEventsSubscription.unsubscribe();
    }
    if (this.startNewAppSubscription != undefined) {
      this.startNewAppSubscription.unsubscribe();
    }
    if (this.updateApplicationSubscription != undefined) {
      this.updateApplicationSubscription.unsubscribe();
    }
  }
}
