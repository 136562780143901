<!DOCTYPE html>
<html lang="en">
  <head>
    <meta charset="utf-8">
    <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no">
    <meta name="description" content="">
    <meta name="author" content="">
    <title>Please sign in</title>
    <link href="https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0-beta/css/bootstrap.min.css" rel="stylesheet" integrity="sha384-/Y6pD6FV/Vv2HJnA6t+vslU6fwYXjCFtcEpHbNJ0lyAFsXTsjBbfaDjzALeQsN6M" crossorigin="anonymous">
    <link href="https://getbootstrap.com/docs/4.0/examples/signin/signin.css" rel="stylesheet" crossorigin="anonymous"/>
  </head>
  <body>
	<header>
		<h2>SECC Charity Admin</h2>
		<h5>Login</h5>
		<hr/>
	</header>

	  <article class="artcle">
     <div class="container">
      <form ngNoForm class="form-signin" method="post" action="admin/auth/dologin">
        <!--<h2 class="form-signin-heading">Service Desk (Test) Login </h2>-->
        <p>
          <label for="username" class="sr-only">Username</label> Username
          <input type="text" id="username" name="username" class="form-control" placeholder="Username" required autofocus>
        </p>
        <p>
          <label for="password" class="sr-only">Password</label> Password
          <input type="password" id="password" name="password" class="form-control" placeholder="Password" required>
        </p>
        <button class="btn btn-lg btn-primary btn-block" type="submit">Sign in</button>
      </form>
</div>
		  </article>

	  <footer>
		  <hr/>
		  <div class="copyright">&copy;2023 - <strong>State of Arizona</strong></div>

		  <hr/>
	  </footer>

</body></html>
