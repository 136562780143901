<div>
  <!--ADMIN CERTS -->

  <tbody>
    <tr>
      <td>
        <table
          border="0"
          cellspacing="0"
          style="background-color: White"
          cellpadding="5"
          width="800px"
          align="center"
        >
        <tbody>
          <tr>
            <td width="50%">
              <div
                id="ctl00_ValidationSummary1"
                style="color: Red"
                [hidden]="!isValidationError"
              >
                <h4>Please correct the following errors</h4>
                <ul>
                  <li *ngFor="let error of errMessages">
                    {{ error }}
                  </li>
                </ul>
              </div>
            </td>
            <td width="50%"></td>
          </tr>
        </tbody>
        </table>

        <div
          id="ctl00_pageContent_ModalPopupExtender_foregroundElement"
          style="display: none; position: fixed"
        >
          <div
            id="ctl00_pageContent_Panel1"
            class="modalPopup"
            style="display: none"
          >
            <div
              id="ctl00_pageContent_Panel3"
              style="
                cursor: move;
                background-color: #ffffff;
                border: solid 1px Gray;
                color: Black;
              "
            >
              <div>
                <br />
                <table width="90%" align="center">
                  <tbody>
                    <tr>
                      <td
                        style="
                          color: White;
                          background-color: Blue;
                          font-weight: bold;
                        "
                        align="center"
                      >
                        Error Messages
                      </td>
                    </tr>
                    <tr>
                      <td>&nbsp;</td>
                    </tr>
                    <tr>
                      <td>
                        <ul>
                          <div
                            id="ctl00_pageContent_dvError"
                            style="color: Red"
                          >

                          </div>
                        </ul>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <p style="text-align: center">
                  <input
                    type="submit"
                    name="ctl00$pageContent$CancelButton"
                    value="OK"
                    id="ctl00_pageContent_CancelButton"
                    style="width: 100px"
                  />
                </p>
              </div>
            </div>
          </div>
        </div>
        <div
          id="ctl00_pageContent_pnlContent"
          onkeypress="javascript:return WebForm_FireDefaultButton(event, 'ctl00_pageContent_btnNext')"
        >
          <table width="100%">
            <tbody>
              <tr>
                <td align="center">
                  <h3>
                    SECC INDEPENDENT CHARITY AND FEDERATION CERTIFICATIONS FORM
                  </h3>
                  (This information is required by independent charities and
                  federations.)<br />
                  <h3>CERTIFICATION STATEMENTS</h3>
                  (If an applicant anwers "No" to any of the following
                  certifications, please include an explanation.)<br />
                </td>
              </tr>
            </tbody>
          </table>
          <table width="100%">
            <tbody>
              <tr>
                <td>
                  <h3>ADMINISTRATIVE CERTIFICATIONS:</h3>
                </td>
              </tr>
              <tr>
                <td>
                  ARS Section 38-612 (G) states: “There shall be no payroll salary
                  deductions from the compensation of state officers or
                  employees for contributions made to a charitable organization
                  that performs a nonfederally qualified abortion or maintains
                  or operates a facility where a nonfederally qualified abortion
                  is performed for the provision of family planning services.”
                  Per ARS Section 35-196.05, "Nonfederally qualified abortion"
                  means an abortion that does not meet the requirements for
                  federal reimbursement under title XIX of the social security
                  act.<br /><br />
                  In order to comply with ARS Section 38-612 (G), I certify that
                  the organization named in this application (and all its member
                  charity applicants) does not perform nonfederally qualified
                  abortions or maintain or operate a facility where nonfederally
                  qualified abortions are performed for the provision of family
                  planning services.<br />
                  <table
                    id="ctl00_pageContent_ynP_rblYesNo"
                    cellpadding="5"
                    border="0"
                  >
                    <tbody>
                      <tr>
                        <td>
                          <input
                            id="respP_1"
                            type="radio"
                            name="respP"
                            [value]="true"
                            [(ngModel)]="adminCertsModel.respP"
                          /><label for="respP_1">YES</label>
                        </td>
                        <td>
                          <input
                            id="respP_0"
                            type="radio"
                            name="respP"
                            [value]="false"
                            [(ngModel)]="adminCertsModel.respP"
                          /><label for="respP_0">NO</label>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  If no, please explain:<br />
                  <textarea
                    name="explainP"
                    rows="3"
                    cols="20"
                    id="explainP"
                    [(ngModel)]="adminCertsModel.explainP"
                    style="width: 80%"
                    maxlength="250"
                  ></textarea>
                  <span
                    id="ctl00_pageContent_ynP_rfv_rblYesNo"
                    style="color: Red; display: none"
                  ></span>
                </td>
              </tr>
              <tr>
                <td>
                  <hr />
                  &nbsp;
                </td>
              </tr>
              <tr>
                <td>
                  A. I certify that the organization named in this application
                  is (and all its member charity applicants are) recognized by
                  the Internal Revenue Service as tax exempt under 26 U.S.C.
                  501(c)(3) and to which contributions are tax deductible
                  pursuant to 26 U.S.C. 170, and further that it has been in
                  existence as a 501(c)(3) organization for at least three years
                  by the due date of the application.<br />
                  <table
                    id="ctl00_pageContent_ynA_rblYesNo"
                    cellpadding="5"
                    border="0"
                  >
                    <tbody>
                      <tr>
                        <td>
                          <input
                            id="respA_1"
                            type="radio"
                            name="respA"
                            [value]="true"
                            [(ngModel)]="adminCertsModel.respA"
                          /><label for="respA_1">YES</label>
                        </td>
                        <td>
                          <input
                            id="respA_0"
                            type="radio"
                            name="respA"
                            [value]="false"
                            [(ngModel)]="adminCertsModel.respA"
                          /><label for="respA_0">NO</label>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  If no, please explain:<br />
                  <textarea
                    name="explainA"
                    rows="3"
                    cols="20"
                    id="explainA"
                    [(ngModel)]="adminCertsModel.explainA"
                    style="width: 80%"
                    maxlength="250"
                  ></textarea>
                  <span
                    id="ctl00_pageContent_ynA_rfv_rblYesNo"
                    style="color: Red; display: none"
                  ></span>

                  <br />
                  IRS Documentation (Attachment A)<br />
                  <table id="ctl00_pageContent_fuA_tblUpload">
                    <tbody>
                      <tr>
                        <td>&nbsp;</td>
                      </tr>
                      <tr>
                        <td>
                          <span style="font-size: 75%; color: Blue"
                            >Please limit file size to 2048KB.</span
                          >
                          <app-seccfileuploader #attachA
                            [name]="'attachA'"
                            [uploadUrl]="'rest/secconlineapp/admincerts/uploadAttachmtA'"
                            [deleteUrl]="'secconlineapp/admincerts/deleteAttachmtA'"
                            [maxFileSize]="maxFileSz_adminCert"
                            [allowMultiple]="false"
                            [fileType]="fileType_adminCert"
                            [maxFileCount]="maxFileCount_adminCert"
                          >
                          </app-seccfileuploader>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
              <tr>
                <td>
                  <hr />
                  &nbsp;
                </td>
              </tr>
              <tr>
                <td>
                  B. I certify that the organization named in this application
                  is (and all its member charity applicants are) directed by an
                  active and responsible governing body and whose members have
                  no material conflict of interest and, a majority of which
                  serve without compensation.<br />
                  <table
                    id="ctl00_pageContent_ynB_rblYesNo"
                    cellpadding="5"
                    border="0"
                  >
                    <tbody>
                      <tr>
                        <td>
                          <input
                            id="respB_1"
                            type="radio"
                            name="respB"
                            [value]="true"
                            [(ngModel)]="adminCertsModel.respB"
                          /><label for="respB_1">YES</label>
                        </td>
                        <td>
                          <input
                            id="respB_0"
                            type="radio"
                            name="respB"
                            [value]="false"
                            [(ngModel)]="adminCertsModel.respB"
                          /><label for="respB_0">NO</label>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  If no, please explain:<br />
                  <textarea
                    name="explainB"
                    rows="3"
                    cols="20"
                    id="explainB"
                    [(ngModel)]="adminCertsModel.explainB"
                    style="width: 80%"
                    maxlength="250"
                  ></textarea>
                  <span
                    id="ctl00_pageContent_ynB_rfv_rblYesNo"
                    style="color: Red; display: none"
                  ></span>

                  <br />
                  List of current board members and the date of the most recent
                  board meeting (Attachment B)<br />
                  <table id="ctl00_pageContent_fuB_tblUpload">
                    <tbody>
                      <tr><td>&nbsp;</td></tr>
                      <tr>
                        <td>
                          <span style="font-size: 75%; color: Blue"
                            >Please limit file size to 1024KB.</span
                          >
                          <app-seccfileuploader #attachB
                            [name]="'attachB'"
                            [uploadUrl]="'rest/secconlineapp/admincerts/uploadAttachmtB'"
                            [deleteUrl]="'secconlineapp/admincerts/deleteAttachmtB'"
                            [maxFileSize]="maxFileSz_adminCert"
                            [allowMultiple]="false"
                            [fileType]="fileType_adminCert"
                            [maxFileCount]="maxFileCount_adminCert"
                          >
                          </app-seccfileuploader>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span
                            id="ctl00_pageContent_fuB_lv_lblRowCount"
                            style="color: Red; display: none"
                          ></span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
              <tr>
                <td>
                  <hr />
                  &nbsp;
                </td>
              </tr>
              <tr>
                <td>
                  C. I certify that the organization named in this application
                  has (and all its member charity applicants have) the PRIMARY
                  purpose of providing direct health and/or human service,
                  environmental service, historical preservation or animal
                  welfare service.<br />
                  <table
                    id="ctl00_pageContent_ynC_rblYesNo"
                    cellpadding="5"
                    border="0"
                  >
                    <tbody>
                      <tr>
                        <td>
                          <input
                            id="respC_1"
                            type="radio"
                            name="respC"
                            [value]="true"
                            [(ngModel)]="adminCertsModel.respC"
                          /><label for="respC_1">YES</label>
                        </td>
                        <td>
                          <input
                            id="respC_0"
                            type="radio"
                            name="respC"
                            [value]="false"
                            [(ngModel)]="adminCertsModel.respC"
                          /><label for="respC_0">NO</label>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  If no, please explain:<br />
                  <textarea
                    name="explainC"
                    rows="3"
                    cols="20"
                    id="explainC"
                    [(ngModel)]="adminCertsModel.explainC"
                    style="width: 80%"
                    maxlength="250"
                  ></textarea>
                  <span
                    id="ctl00_pageContent_ynC_rfv_rblYesNo"
                    style="color: Red; display: none"
                  ></span>

                  <br />
                  Independent charities and federations must include supporting
                  documentation that demonstrates that the charity has the
                  PRIMARY purpose of providing direct health and/or human
                  service, environmental service, historical preservation or
                  animal welfare service. Federations applying for member
                  charity applicants should keep this information on file in the
                  event that the SECC asks for supporting documentation for an
                  individual member charity (Attachment C).<br />
                  <table id="ctl00_pageContent_fuC_tblUpload">
                    <tbody>
                      <tr><td>&nbsp;</td></tr>
                      <tr>
                        <td>
                          <span style="font-size: 75%; color: Blue"
                            >Please limit file size to 1024KB.</span
                          >
                          <app-seccfileuploader #attachC
                            [name]="'attachC'"
                            [uploadUrl]="'rest/secconlineapp/admincerts/uploadAttachmtC'"
                            [deleteUrl]="'secconlineapp/admincerts/deleteAttachmtC'"
                            [maxFileSize]="maxFileSz_adminCert"
                            [allowMultiple]="false"
                            [fileType]="fileType_adminCert"
                            [maxFileCount]="maxFileCount_adminCert"
                          >
                          </app-seccfileuploader>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
              <tr>
                <td>
                  <hr />
                  &nbsp;
                </td>
              </tr>
              <tr>
                <td>
                  D. I certify that the organization named in this application
                  has (and all its member charity applicants have) a Direct and
                  Substantial Local Presence.<br />
                  <table id="ctl00_pageContent_rblD" cellpadding="5" border="0">
                    <tbody>
                      <tr>
                        <td>
                          <input
                            id="respD_1"
                            type="radio"
                            name="respD"
                            [value]="1"
                            [(ngModel)]="adminCertsModel.respD"
                          /><label for="respD_1">YES</label>
                        </td>
                        <td>
                          <input
                            id="respD_0"
                            type="radio"
                            name="respD"
                            [value]="0"
                            [(ngModel)]="adminCertsModel.respD"
                          /><label for="respD_0">NO</label>
                        </td>
                        <td>
                          <input
                            id="respD_2"
                            type="radio"
                            name="respD"
                            [value]="2"
                            [(ngModel)]="adminCertsModel.respD"
                          /><label for="respD_2">Not Applicable</label>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  If no, please explain:<br />
                  <textarea
                    name="explainD"
                    rows="3"
                    cols="20"
                    id="explainD"
                    [(ngModel)]="adminCertsModel.explainD"
                    style="width: 80%"
                    maxlength="250"
                  ></textarea><br>
                  Supporting documentation that demonstrates your local or
                  statewide presence (Attachment D)<br />

                  <table id="ctl00_pageContent_fuD_tblUpload">
                    <tbody>
                      <tr><td>&nbsp;</td></tr>
                      <tr>
                        <td>
                          <span style="font-size: 75%; color: Blue"
                            >Please limit file size to 1024KB.</span
                          >
                          <app-seccfileuploader #attachD
                            [name]="'attachD'"
                            [uploadUrl]="'rest/secconlineapp/admincerts/uploadAttachmtD'"
                            [deleteUrl]="'secconlineapp/admincerts/deleteAttachmtD'"
                            [maxFileSize]="maxFileSz_adminCert"
                            [allowMultiple]="false"
                            [fileType]="fileType_adminCert"
                            [maxFileCount]="maxFileCount_adminCert"
                          >
                          </app-seccfileuploader>
                        </td>
                      </tr>
                    </tbody>
                  </table>
<!--
                  If no, please explain:<br />
                  <textarea
                    name="explainD"
                    rows="3"
                    cols="20"
                    id="explainD"
                    [(ngModel)]="adminCertsModel.explainD"
                    style="width: 80%"
                  ></textarea>
-->
                </td>
              </tr>
              <tr>
                <td>
                  <hr />
                  &nbsp;
                </td>
              </tr>
              <tr>
                <td>
                  E. I certify that the organization named in this application
                  (and all its member charity applicants) prohibits the sale or
                  lease of the contributor lists, and does not permit payments
                  of commissions, finder’s fee, percentages, bonuses, or similar
                  practices in connection with their fundraising activities.<br />
                  <table
                    id="ctl00_pageContent_ynE_rblYesNo"
                    cellpadding="5"
                    border="0"
                  >
                    <tbody>
                      <tr>
                        <td>
                          <input
                            id="respE_1"
                            type="radio"
                            name="respE"
                            [value]="true"
                            [(ngModel)]="adminCertsModel.respE"
                          /><label for="respE_1">YES</label>
                        </td>
                        <td>
                          <input
                            id="respE_0"
                            type="radio"
                            name="respE"
                            [value]="false"
                            [(ngModel)]="adminCertsModel.respE"
                          /><label for="respD_0">NO</label>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  If no, please explain:<br />
                  <textarea
                    name="explainE"
                    rows="3"
                    cols="20"
                    id="explainE"
                    [(ngModel)]="adminCertsModel.explainE"
                    style="width: 80%"
                    maxlength="250"
                  ></textarea>
                  <span
                    id="ctl00_pageContent_ynE_rfv_rblYesNo"
                    style="color: Red; display: none"
                  ></span>
                </td>
              </tr>
              <tr>
                <td>
                  <hr />
                  &nbsp;
                </td>
              </tr>
              <tr>
                <td>
                  F. I certify that the organization named in this application
                  conducts (and all its member charity applicants conduct)
                  publicity and promotional activities based upon its actual
                  program and operations, that these activities are truthful and
                  non-deceptive, include all material facts, and make no
                  exaggerated or misleading claims.<br />
                  <table
                    id="ctl00_pageContent_ynF_rblYesNo"
                    cellpadding="5"
                    border="0"
                  >
                    <tbody>
                      <tr>
                        <td>
                          <input
                            id="respF_1"
                            type="radio"
                            name="respF"
                            [value]="true"
                            [(ngModel)]="adminCertsModel.respF"
                          /><label for="respF_1">YES</label>
                        </td>
                        <td>
                          <input
                            id="respF_0"
                            type="radio"
                            name="respF"
                            [value]="false"
                            [(ngModel)]="adminCertsModel.respF"
                          /><label for="respD_0">NO</label>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  If no, please explain:<br />
                  <textarea
                    name="explainF"
                    rows="3"
                    cols="20"
                    id="explainF"
                    [(ngModel)]="adminCertsModel.explainF"
                    style="width: 80%"
                    maxlength="250"
                  ></textarea>
                  <span
                    id="ctl00_pageContent_ynF_rfv_rblYesNo"
                    style="color: Red; display: none"
                  ></span>
                </td>
              </tr>
              <tr>
                <td>
                  <hr />
                  &nbsp;
                </td>
              </tr>
              <tr>
                <td>
                  G. I certify that the organization named in this application
                  has (and all its member charity applicants have) a policy
                  regarding the practice of non-discrimination. It does not
                  discriminate on the basis of race, color, religion, sex, age,
                  national origin or physical or mental disability against
                  persons who are served, against employed staff, and against
                  members of the governing body.<br />
                  <table
                    id="ctl00_pageContent_ynG_rblYesNo"
                    cellpadding="5"
                    border="0"
                  >
                    <tbody>
                      <tr>
                        <td>
                          <input
                            id="respG_1"
                            type="radio"
                            name="respG"
                            [value]="true"
                            [(ngModel)]="adminCertsModel.respG"
                          /><label for="respG_1">YES</label>
                        </td>
                        <td>
                          <input
                            id="respG_0"
                            type="radio"
                            name="respG"
                            [value]="false"
                            [(ngModel)]="adminCertsModel.respG"
                          /><label for="respG_0">NO</label>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  If no, please explain:<br />
                  <textarea
                    name="explainG"
                    rows="3"
                    cols="20"
                    id="explainG"
                    [(ngModel)]="adminCertsModel.explainG"
                    style="width: 80%"
                    maxlength="250"
                  ></textarea>
                  <span
                    id="ctl00_pageContent_ynG_rfv_rblYesNo"
                    style="color: Red; display: none"
                  ></span>
                </td>
              </tr>
              <tr>
                <td>
                  <hr />
                  &nbsp;
                </td>
              </tr>
              <tr>
                <td colspan="4" style="text-align: right">
                  <input
                    type="image"
                    width="100"
                    name="ctl00$pageContent$btnNext"
                    id="ctl00_pageContent_btnNext"
                    src="assets/img/continue-button.jpg"
                    (click)="continue()"
                    style="border-width: 0px"
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div
          id="ctl00_pageContent_ModalPopupExtender_backgroundElement"
          class="modalBackground"
          style="display: none; position: fixed; left: 0px; top: 0px"
        ></div>
      </td>
    </tr>
  </tbody>
</div>
