import { FinCertsFcModel } from './../models/FinCertsFcModel';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';

import { SecccharityService } from './../../services/secccharity.service';
import { SessiontimeoutService } from 'src/app/services/sessiontimeout.service';
import { SeccfileuploaderComponent } from './../seccfileuploader/seccfileuploader.component';
@Component({
  selector: 'app-financialcertsfc',
  templateUrl: './financialcertsfc.component.html',
  styleUrls: ['./financialcertsfc.component.css']
})
export class FinancialcertsfcComponent implements OnInit {

  constructor(
    private secccharityService: SecccharityService,
    private sessiontimeoutService : SessiontimeoutService,
    private router: Router
  ) { }


  @ViewChild('attachE')
  attachERef: SeccfileuploaderComponent;

  @ViewChild('attachF')
  attachFRef: SeccfileuploaderComponent;

  isValidationError: boolean = false;
  errMessages: Array<string> = new Array<string>();

  fileType_finCert: Array<string> = ['image','doc','pdf'];
  maxFileCount_finCert: number = 3;
  maxFileSz_finCert: number = 2048 * 1024;

  campaignYear : number;
  finCertsfcModel: FinCertsFcModel;
  finCertsFcSubscription: Subscription = new Subscription();
  campaignYearSubscription: Subscription = new Subscription();

  ngOnInit(): void {

        this.sessiontimeoutService.reset();
        //If previously saved to service object, copy from there.
        this.finCertsfcModel = this.secccharityService.finCertsfcModel;
        if (this.finCertsfcModel == undefined) {

          this.finCertsfcModel = new FinCertsFcModel();
        }
        this.setCampaignYear();
  }
  ngAfterViewInit() {
    this.secccharityService.scrollToTopFast();
  }

  public continue() {
    this.isValidationError = false; // initialize

    //save raw state to service object
    this.secccharityService.finCertsfcModel = this.finCertsfcModel;
    this.updateFinCertFc();
  }

  updateFinCertFc() {

    this.finCertsFcSubscription = this.secccharityService
      .updateFinCertFc(this.finCertsfcModel)
      .subscribe(
        (response) => {
          if (!response.isInvalidReq && response.status == 'success') {

            this.saveUploaderQueueState(); // Save file item queue to recreate queue if/when returning to page
            this.router.navigate(['submit']);
          }
          if (response.invalidReq) {
            // set component validation and err flags
            this.isValidationError = true;
            this.errMessages = response.validationResult.errMessages;
            console.log(this.errMessages);
            this.secccharityService.scrollToTop();
          }

        },
        (err) => {
          console.log(err);
        }
      );
  }

  setCampaignYear() {

    this.campaignYearSubscription = this.secccharityService.getCampaignYear()
    .subscribe((response) => {

        this.campaignYear = this.secccharityService.campaignYear = parseInt(response as string);
    },
    (err) => {
      console.log(err);
    });
  }

    //Save file uploader queues to a map
    saveUploaderQueueState() {

      this.secccharityService.uploaderQueueMap.set('attachE',this.attachERef.uploader.queue);
      this.secccharityService.uploaderQueueMap.set('attachF',this.attachFRef.uploader.queue);
    }
}


