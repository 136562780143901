import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ApptypeComponent } from './secconlineapp/apptype/apptype.component';
import { InstructionsComponent } from './secconlineapp/instructions/instructions.component';
import { GeneralinfoComponent } from './secconlineapp/generalinfo/generalinfo.component';
import { SoftfilereqComponent } from './secconlineapp/softfilereq/softfilereq.component';
import { AdmincertsComponent } from './secconlineapp/admincerts/admincerts.component';
import { FinancialcertsComponent } from './secconlineapp/financialcerts/financialcerts.component';
import { SubmitComponent } from './secconlineapp/submit/submit.component';
import { FileUploadModule } from 'ng2-file-upload';
import { SeccfileuploaderComponent } from './secconlineapp/seccfileuploader/seccfileuploader.component';
import {ThankyouComponent} from '../app/secconlineapp/thankyou/thankyou.component';
import { FinancialcertsfcComponent } from './secconlineapp/financialcertsfc/financialcertsfc.component';
import { ApplicationslistComponent } from './secconlineapp/admin/applicationslist/applicationslist.component';

import { NgIdleKeepaliveModule } from '@ng-idle/keepalive'; // this includes the core NgIdleModule but includes keepalive providers for easy wireup
import { MatDialogModule } from "@angular/material/dialog";
import { IdletimeoutdialogComponent } from './secconlineapp/idletimeoutdialog/idletimeoutdialog.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { GoogleChartsModule } from 'angular-google-charts';


//Angular Material
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatIconModule} from '@angular/material/icon';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatRadioModule} from '@angular/material/radio';
import {MatInputModule} from '@angular/material/input';
import {MatTooltipModule} from '@angular/material/tooltip';
import { LoginComponent } from './secconlineapp/admin/applicationslist/login/login.component';





@NgModule({
  declarations: [		//declare every single secc component here
    AppComponent,
    InstructionsComponent,
    ApptypeComponent,
    GeneralinfoComponent,
    AdmincertsComponent,
    SoftfilereqComponent,
    FinancialcertsComponent,
    SubmitComponent,
    SeccfileuploaderComponent,
    ThankyouComponent,
    FinancialcertsfcComponent,
    IdletimeoutdialogComponent,
    ApplicationslistComponent,
    LoginComponent
   ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    CommonModule,
    FormsModule,
    HttpClientModule,
    FileUploadModule,
    NgIdleKeepaliveModule.forRoot(),
    MatDialogModule,
    BrowserAnimationsModule,
    GoogleChartsModule,

    //Angular Material

    MatFormFieldModule,
    MatIconModule,
    MatButtonToggleModule,
    MatSlideToggleModule,
    MatRadioModule,
    MatInputModule,
    MatTooltipModule

  ],
  providers: [],
    bootstrap: [AppComponent]
})
export class AppModule { }
