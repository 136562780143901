<div></div>

<div></div>
<!-- Leaving empty  div tags to preserve original formatting -->

<div></div>

<div>
  <table
    border="0"
    cellspacing="0"
    cellpadding="0"
    width="850px"
    align="center"
    style="background-color: lightgray"
  >
    <tbody>
      <tr style="background-color: White">
        <td>
          <table width="10" height="100"></table>
        </td>
        <td>
          <img
            id="ctl00_imgHeader"
            src="assets/img/SECCHeart25.png"
            alt="SECC Header and Logo"
            style="height: 90px; width: 130px; border-width: 0px"
          />
        </td>
        <td width="380" align="center" valign="top">
          <table width="640" height="100">
            <tbody>
              <tr>
                <td></td>
              </tr>
              <tr align="center">
                <td>
                  <span style="font-size: large; color: Navy"
                    >CHARITY/FEDERATION APPLICATION</span
                  >
                </td>
              </tr>
              <tr>
                <td></td>
              </tr>
            </tbody>
          </table>
        </td>
      </tr>
      <tr *ngIf="this.charityadminService.isAdmin"><td style="text-align:left;"><a href="admin/auth/dologout">logout</a></td></tr>
    </tbody>
  </table>
  <table
    border="0"
    cellspacing="0"
    style="background-color: Black"
    cellpadding="5"
    width="850px"
    align="center"
  >
    <tbody>
      <tr  *ngIf="this.charityadminService.isShowAdminMenu">
        <td>
          <table width="100%">
            <tbody>
              <tr>
                <td align="center">
                  <a href="#ctl00_Menu1_SkipLink"
                    ><!-- <img alt="Skip Navigation Links" src="/SECCOnlineApp/WebResource.axd?d=ek_mO0-NUNxXetsCtHxMUoynFjJ7a7U_JIjQDmWRGqXeikj6_HexxDPodjew1nskEvPHeBq582MS86yMrrCX1Xm4E9kGtXQyhnTmiRXeVEw1&amp;t=637453816754849868" width="0" height="0" style="border-width:0px;">--></a
                  >
                  <table
                    id="ctl00_Menu1"
                    class="ctl00_Menu1_5 ctl00_Menu1_2"
                    cellpadding="0"
                    cellspacing="0"
                    border="0"
                  >
                    <tbody>
                      <tr>
                        <td id="ctl00_Menu1n0">
                          <table
                            id="generalinfo"
                            class="ctl00_Menu1_4"
                            cellpadding="0"
                            cellspacing="0"
                            border="0"
                            width="100%"
                            (mouseenter)="onMouseEnter('generalinfo')"
                            (mouseleave)="onMouseLeave('generalinfo')"
                          >
                            <tbody>
                              <tr>
                                <td style="white-space: nowrap">
                                  <a
                                    class="ctl00_Menu1_1 ctl00_Menu1_3"
                                    (click)="navigate('generalinfo')"
                                    >Step 1: General Info</a
                                  >
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </td>
                        <td style="width: 3px"></td>
                        <td id="ctl00_Menu1n1">
                          <table
                            id="softfilereq"
                            class="ctl00_Menu1_4"
                            cellpadding="0"
                            cellspacing="0"
                            border="0"
                            width="100%"
                            (mouseenter)="onMouseEnter('softfilereq')"
                            (mouseleave)="onMouseLeave('softfilereq')"
                          >
                            <tbody>
                              <tr>
                                <td style="white-space: nowrap">
                                  <a
                                    class="ctl00_Menu1_1 ctl00_Menu1_3"
                                    (click)="navigate('softfilereq')"
                                    >Step 2: Soft File Uploads</a
                                  >
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </td>
                        <td style="width: 3px"></td>
                        <td id="ctl00_Menu1n2" [hidden]="isFC()">
                          <table
                            id="admincerts"
                            class="ctl00_Menu1_4"
                            cellpadding="0"
                            cellspacing="0"
                            border="0"
                            width="100%"
                            (mouseenter)="onMouseEnter('admincerts')"
                            (mouseleave)="onMouseLeave('admincerts')"
                          >
                            <tbody>
                              <tr>
                                <td style="white-space: nowrap">
                                  <a
                                    class="ctl00_Menu1_1 ctl00_Menu1_3"
                                    (click)="navigate('admincerts')"
                                    >Step 3: Admin Certs</a
                                  >
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </td>
                        <td style="width: 3px"></td>
                        <td id="ctl00_Menu1n3">
                          <table
                            id="financialcerts"
                            class="ctl00_Menu1_4"
                            cellpadding="0"
                            cellspacing="0"
                            border="0"
                            width="100%"
                            (mouseenter)="onMouseEnter('financialcerts')"
                            (mouseleave)="onMouseLeave('financialcerts')"
                          >
                            <tbody>
                              <tr>
                                <td style="white-space: nowrap">
                                  <a
                                    class="ctl00_Menu1_1 ctl00_Menu1_3"
                                    (click)="navigate('financialcerts')"
                                    >Step {{ isFC() ? "3" : "4" }}: Financial
                                    Certs</a
                                  >
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </td>
                        <td style="width: 3px"></td>
                        <td id="ctl00_Menu1n4">
                          <table
                            id="submit"
                            class="ctl00_Menu1_4"
                            cellpadding="0"
                            cellspacing="0"
                            border="0"
                            width="100%"
                            (mouseenter)="onMouseEnter('submit')"
                            (mouseleave)="onMouseLeave('submit')"
                          >
                            <tbody>
                              <tr>
                                <td style="white-space: nowrap">
                                  <a
                                    class="ctl00_Menu1_1 ctl00_Menu1_3"
                                    (click)="navigate('submit')"
                                    >Step {{ isFC() ? "4" : "5" }}: Submit</a
                                  >
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <a id="ctl00_Menu1_SkipLink"></a>
                </td>
              </tr>
            </tbody>
          </table>
        </td>
      </tr>
      <tr
        style="background-color: gray"
        *ngIf="this.charityadminService.isShowAdminMenu"
      >
        <table width="100%">
          <tbody>
            <tr>
              <td align="left">
                <a
                  routerLink="admin"
                  id="ctl00_approve_lkbAdminHome"
                  style="color: White; font-weight: bold"
                  >Admin Home</a>

              </td>

              <td class="label">
                <span id="ctl00_approve_txtCode_uclbl">Code</span>:
              </td>
              <td id="ctl00_approve_txtCode_tdTxt">
                <input
                  name="ctl00$approve$txtCode$uctxt"
                  type="text"
                  maxlength="10"
                  id="ctl00_approve_txtCode_uctxt"
                  style="width: 170px"
                  [value]="this.charityadminService.code"
                  disabled
                /><span
                  id="ctl00_approve_txtCode_ucrev"
                  style="color: Red; display: none"
                ></span>
                <span
                  id="ctl00_approve_txtCode_ucrfv"
                  style="color: Red; display: none"
                ></span>
              </td>

              <td align="center">
                <button
                  mat-raised-button
                  name="ctl00$approve$btnReview"
                  value="Mark Reviewed"
                  id="ctl00_approve_btnReview"
                  style="
                    color: White;
                    background-color: rgb(30, 43, 158);
                    width: 100px;
                  "
                  (click)="updateNonProfitStatus('R')"
                >
                  Reviewed
                </button>
              </td>

              <td align="center">
                <button
                  mat-raised-button
                  name="ctl00$approve$btnApprove"
                  value="Approve"
                  id="ctl00_approve_btnApprove"
                  style="
                    color: White;
                    background-color: rgb(6, 83, 32);
                    width: 100px;
                  "
                  (click)="updateNonProfitStatus('A')"
                >
                  Approve
                </button>
              </td>

              <td align="center">
                <button
                  mat-raised-button
                  name="ctl00$approve$btnProvisional"
                  value="Provisional"
                  id="ctl00_approve_btnProvisional"
                  style="
                    color: Black;
                    background-color: rgb(243, 247, 8);
                    width: 100px;
                  "
                  (click)="updateNonProfitStatus('PL')"
                >
                  Provisional
                </button>
              </td>
              <td align="center">
                <button
                  mat-raised-button
                  name="ctl00$approve$btnDecline"
                  value="Decline"
                  id="ctl00_approve_btnDecline"
                  style="
                    color: White;
                    background-color: rgb(202, 29, 46);
                    width: 100px;
                  "
                  (click)="updateNonProfitStatus('D')"
                >
                  Decline
                </button>
              </td>
              <td align="center">
                <button
                  name="ctl00$approve$btnIncluded"
                  value="Included"
                  id="ctl00_approve_btnIncluded"
                  style="
                    color: White;
                    background-color: rgb(127, 1, 127);
                    width: 100px;
                  "
                  (click)="updateNonProfitStatus('INC')"
                >
                  Included
                </button>
              </td>
            </tr>
            <tr >
              <td *ngIf="this.charityadminService.isApplicationLoaded"
                id="applicationStatusBand"
                colspan="7"
                bgColor="gray"
              >
                <span
                  id="applicationStatusText"
                  style="display: block;color: white;text-align: center;"
                  >{{this.charityadminService.showApplicationStatus()}}</span
                >
              </td>
            </tr>

          </tbody>
        </table>
      </tr>

    </tbody>
  </table>

  &nbsp;
  <table
    id="appcontent"
    border="0"
    cellspacing="0"
    style="background-color: White"
    cellpadding="5"
    width="850px"
    align="center"
    class="shadow"
  >

    <!-- SECC pages (app components) appear here. Wrap inside <tbody> tags -->

    <router-outlet (activate)="onActivate($event)"></router-outlet>
  </table>
  <br />

  <table
    border="0"
    style="background-color: Black; color: Gray"
    cellpadding="5"
    width="850px"
    align="center"
  >
    <tbody>
      <tr>
        <td align="center">
          <b>Version: 3.0.0 </b>:: CONFIDENTIAL - Department of Administration;
          State of Arizona
        </td>
        <td></td>
      </tr>
    </tbody>
  </table>
</div>

<div
  class="ui-dialog ui-widget ui-widget-content ui-corner-all ui-draggable ui-resizable"
  tabindex="-1"
  role="dialog"
  aria-labelledby="ui-dialog-title-ctl00_Timeout1"
  style="
    display: none;
    position: absolute;
    overflow: hidden;
    z-index: 1000;
    outline: 0px;
  "
>
  <div
    class="ui-dialog-titlebar ui-widget-header ui-corner-all ui-helper-clearfix"
    unselectable="on"
  >
    <span
      class="ui-dialog-title"
      id="ui-dialog-title-ctl00_Timeout1"
      unselectable="on"
      >Session Expiring</span
    ><a
      href="#"
      class="ui-dialog-titlebar-close ui-corner-all"
      role="button"
      unselectable="on"
      ><span class="ui-icon ui-icon-closethick" unselectable="on"
        >close</span
      ></a
    >
  </div>
  <span
    id="ctl00_Timeout1"
    style=""
    class="ui-dialog-content ui-widget-content"
  >
    <p>
      <span
        class="ui-icon ui-icon-alert"
        style="float: left; margin: 1px 10px 20px 0"
      >
      </span
      >Your session will expire in:
    </p>
    <span id="tscCounter"></span>&nbsp;minutes
    <p>Click <b>OK</b> to continue your session.</p>
  </span>
  <div class="ui-resizable-handle ui-resizable-n" unselectable="on"></div>
  <div class="ui-resizable-handle ui-resizable-e" unselectable="on"></div>
  <div class="ui-resizable-handle ui-resizable-s" unselectable="on"></div>
  <div class="ui-resizable-handle ui-resizable-w" unselectable="on"></div>
  <div
    class="ui-resizable-handle ui-resizable-se ui-icon ui-icon-gripsmall-diagonal-se ui-icon-grip-diagonal-se"
    unselectable="on"
    style="z-index: 1001"
  ></div>
  <div
    class="ui-resizable-handle ui-resizable-sw"
    unselectable="on"
    style="z-index: 1002"
  ></div>
  <div
    class="ui-resizable-handle ui-resizable-ne"
    unselectable="on"
    style="z-index: 1003"
  ></div>
  <div
    class="ui-resizable-handle ui-resizable-nw"
    unselectable="on"
    style="z-index: 1004"
  ></div>
  <div class="ui-dialog-buttonpane ui-widget-content ui-helper-clearfix">
    <button type="button" class="ui-state-default ui-corner-all">Ok</button>
  </div>
</div>

<!-- IDLE TIMEOUT TEST-->
<!--
<p><strong>{{idleState}}</strong></p>
      <p *ngIf="lastPing"><small>Last keepalive ping <strong>{{lastPing | amTimeAgo}}</strong></small></p>
      <button (click)="reset()" *ngIf="timedOut">Restart</button>
-->
