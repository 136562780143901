<table
  border="0"
  cellspacing="0"
  style="background-color: White"
  cellpadding="5"
  width="850px"
  align="center"
  class="shadow"
>
  <tbody>
    <tr>
      <td>
        <table
          border="0"
          cellspacing="0"
          style="background-color: White"
          cellpadding="5"
          width="800px"
          align="center"
        >
          <tbody>
            <tr>
              <td>
                <div
                  id="ctl00_ValidationSummary1"
                  style="color: Red; display: none"
                ></div>
              </td>
            </tr>
          </tbody>
        </table>

        <table width="100%">
          <tbody>
            <tr>
              <td align="center">
                <br />
                <span style="color: Green; font-size: large"
                  >Thank You for successfully completing the AZSECC
                  Application!</span
                >
                <br />
                Your Application Reference Number is: <font color="red">{{nonProfitID}}</font>
                <span
                  id="ctl00_pageContent_lblRefNo"
                  style="color: Red; font-weight: bold"
                ></span>
                <br /><br />
                Please allow 8-12 weeks for our staff to review the application.
                If you have questions or need to make changes to the
                application, please email Linda Stiles at
                <a href="mailto:Linda.Stiles@azdoa.gov"
                  >Linda.Stiles@azdoa.gov</a
                >. <br /><br />
              </td>
            </tr>
          </tbody>
        </table>
      </td>
    </tr>
  </tbody>
</table>
