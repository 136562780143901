import { SecccharityService } from './../../services/secccharity.service';
import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-thankyou',
  templateUrl: './thankyou.component.html',
  styleUrls: ['./thankyou.component.css']
})
export class ThankyouComponent implements OnInit {

  constructor(private secccharityService : SecccharityService) { }

  nonProfitID : string;
  thankYouSubscription : Subscription = new Subscription();

  ngOnInit() {
    this.secccharityService.getNonProfitId()
                            .subscribe(response => {
                              this.nonProfitID = response as string;
                              console.log('New NP id: ');
                              console.log(response);
                            }),
                            (err) => {
                              console.log('ERROR: ');
                              console.log(err);
                            }
  }
  ngOnDestroy() {
    if (this.thankYouSubscription != undefined)
      this.thankYouSubscription.unsubscribe();
  }

}
