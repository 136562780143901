import { SessiontimeoutService } from 'src/app/services/sessiontimeout.service';
import { CharityadminService } from './../../../services/charityadmin.service';
import { SecccharityService } from './../../../services/secccharity.service';
import { Component, OnInit, NgZone } from '@angular/core';
import { ChartType, Row,GoogleChartComponent } from "angular-google-charts";
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';

declare function retrieveNonProfitApplication(refno)

@Component({
  selector: 'app-applicationslist',
  templateUrl: './applicationslist.component.html',
  styleUrls: ['./applicationslist.component.css']
})
export class ApplicationslistComponent implements OnInit {

  retrieveAppScriptElement: HTMLScriptElement;

  //tableData = new Array<any>();
  chartParams : any = {};
  chartColumns = ['Reference #',	'Code',	'Title',	'Application Type', 'Federation Name', 'Application Status'];

  //Search params
  refno : string ='';
  title : string = '';


  appsSummarySubscription : Subscription = new Subscription();
  appsSearchSummarySubscription : Subscription = new Subscription();
  newAppSubscription : Subscription = new Subscription(); // Adding new application
  retrieveAppSubscription : Subscription = new Subscription();

  constructor(private router : Router,
              private charityadminService : CharityadminService,
              private sessiontimeoutService : SessiontimeoutService,
              private secccharityService : SecccharityService,
              private ngZone : NgZone
            ) {
              this.retrieveAppScriptElement = document.createElement("script");
              this.retrieveAppScriptElement.src = "assets/scripts/retrieveApp.js";
              document.body.appendChild(this.retrieveAppScriptElement);
            }




  ngOnInit() {

    this.charityadminService.isAdmin = true;
    this.charityadminService.isShowAdminMenu = false;  //show admin toolbar/menu only after an application is loaded
    this.sessiontimeoutService.reset();

    // angularComponentReference  defined here
    window['angularComponentReference'] = {
              component: this,
              zone: this.ngZone,
              loadAngularFunction: (refno) => this.retrieveApplication(refno),
    };

    this.chartParams = {
      type: ChartType.Table,
      data: [],
      chartColumns: this.chartColumns,
      options: {
        showRowNumber: true,
        page: 'enable',
        pageSize: 25,
        width: '100%',
        height: '100%',
        allowHtml: true,
        pagingSymbols: {
            prev: 'prev',
            next: 'next'
        },
        pagingButtonsConfiguration: 'auto'
      }
    }
    this.getApplicationsSummary();
  }

  // Get summaries of all existing applications
  getApplicationsSummary() {

    this.appsSummarySubscription =
                  this.charityadminService.getApplicationsSummary()
                  .subscribe((result) => {
                      this.charityadminService.isApplicationLoaded = false;
                      this.chartParams.data = this.buildTableDataArray(result);
                  },
                  (err) => {
                    console.log(err);
                  }

                  );

  }

  //Search for summaries of applications by NonProfitID , Title
  searchApplicationsSummary() {

    this.appsSummarySubscription =
                  this.charityadminService.searchApplicationsSummary(this.refno, this.title)
                  .subscribe((result) => {
                      this.chartParams.data = this.buildTableDataArray(result);
                  },
                  (err) => {
                    console.log(err);
                  });
  }

  //Retrieve an existing application (full object graph). refno = NonProfitID
  retrieveApplication(refno : string) {

    console.log(refno);
    this.retrieveAppSubscription = this.charityadminService.retrieveApplication(refno)
                                   .subscribe((result) => {
                                      //Update page objects in secccharity service
                                      this.updatePageObjects(result);
                                      this.charityadminService.isApplicationLoaded = true;
                                      this.charityadminService.isShowAdminMenu = true; // Menu will show starting with next page (appType)
                                      this.router.navigate(['apptype']);
                                    },
                                    (err) => {
                                      console.log(err);
                                    });


  }


  /*
   * Converts list of NonProfits into an array of arrays for rendering in Google chart
  */
  private buildTableDataArray(result) : Array<any> {

    let tableData = new Array<any>();
                    tableData = result as any;
                    var size = Object.keys(tableData).length;
                    var tmpArr : Array<any> = new Array<any>();
                      for(var i=0; i < size; i++) {
                        var row =[
                           //   '<a (click)=retrieveApplication(' + tableData[i].id + ')>' + tableData[i].id + '</a>',
                              '<a href="javascript:void(0)" onClick=retrieveNonProfitApplication(' + tableData[i].id + ')>'  + tableData[i].id + '</font></a>',
                              tableData[i].code,
                              tableData[i].title,
                              tableData[i].applicationType,
                              tableData[i].federationName,
                              tableData[i].applicationStatus
                        ] ;
                        tmpArr.push(row);
                      }
      return tmpArr;

  }

  addNewCharity() {

    // (Same initialization that happens in Instructions component)
    this.secccharityService.resetBrowserSession();  //reset Applicaiton session in browser
    this.newAppSubscription = this.secccharityService.startNewApplication()  //reset Applicaiton session in server
                             .subscribe((response) => {
                                 console.log('Starting new application..');
                                 this.router.navigate(['apptype']);
                             },
                             (err) =>{
                                console.log(err);
                             });
  }


  private updatePageObjects(result) {

    console.log(result);
    this.secccharityService.resetBrowserSession(); // init

    this.secccharityService.appType = result.nonProfit.applicationType;
    this.charityadminService.code = result.nonProfit.code;
    this.charityadminService.updateApplicationStatus(result.nonProfit.applicationStatus);

    this.popGenInfo(result);
    this.popAdminFinCerts(result);
    this.popSubmit(result);
  }

  //Admin, Fin & FinFC certs
private popAdminFinCerts(result) {

  this.secccharityService.adminCertsModel.respP = result.adminCert.respP as boolean;
  this.secccharityService.adminCertsModel.explainP = result.adminCert.explainP;
  this.secccharityService.adminCertsModel.respA = result.adminCert.respA as boolean;
  this.secccharityService.adminCertsModel.explainA = result.adminCert.explainA;
  this.secccharityService.adminCertsModel.respB = result.adminCert.respB as boolean;
  this.secccharityService.adminCertsModel.explainB = result.adminCert.explainB;
  this.secccharityService.adminCertsModel.respC = result.adminCert.respC as boolean;
  this.secccharityService.adminCertsModel.explainC = result.adminCert.explainC;
  this.secccharityService.adminCertsModel.respD = result.adminCert.respD;
  this.secccharityService.adminCertsModel.explainD = result.adminCert.explainD;
  this.secccharityService.adminCertsModel.respE = result.adminCert.respE as boolean;
  this.secccharityService.adminCertsModel.explainE = result.adminCert.explainE;
  this.secccharityService.adminCertsModel.respF = result.adminCert.respF as boolean;
  this.secccharityService.adminCertsModel.explainF = result.adminCert.explainF;
  this.secccharityService.adminCertsModel.respG = result.adminCert.respG as boolean;
  this.secccharityService.adminCertsModel.explainG = result.adminCert.explainG;

  this.secccharityService.finCertsModel.respI = result.adminCert.respI as boolean;
  this.secccharityService.finCertsModel.respJ = result.adminCert.respJ as boolean;
  this.secccharityService.finCertsModel.respK = result.adminCert.respK as number;

  this.secccharityService.finCertsModel.respL = result.adminCert.respL as boolean;
  this.secccharityService.finCertsModel.explainL = result.adminCert.explainL;
  this.secccharityService.finCertsModel.respM = result.adminCert.respM as boolean;
  this.secccharityService.finCertsModel.explainM = result.adminCert.explainM;
  this.secccharityService.finCertsModel.respN = result.adminCert.respN as boolean;
  this.secccharityService.finCertsModel.explainN = result.adminCert.explainN;
  this.secccharityService.finCertsModel.respO = result.adminCert.respO as boolean;

  this.secccharityService.finCertsModel.l_A = result.adminCert.la as number;
  this.secccharityService.finCertsModel.l_B = result.adminCert.lb as number;
  this.secccharityService.finCertsModel.l_D = result.adminCert.ld as number;

  ///////Fin FC
  this.secccharityService.finCertsfcModel.respJ = result.adminCert.respJ as boolean;

}




  private popGenInfo(result) {

      //Gen Info
      this.secccharityService.generalInfoModel.title = result.nonProfit.title;
      this.secccharityService.generalInfoModel.legalName = result.nonProfit.legalName;
      this.secccharityService.generalInfoModel.federationId = result.nonProfit.federationID;
      this.secccharityService.generalInfoModel.address = result.nonProfit.address;
      this.secccharityService.generalInfoModel.city = result.nonProfit.city;
      this.secccharityService.generalInfoModel.state = result.nonProfit.state;
      this.secccharityService.generalInfoModel.zip = result.nonProfit.zip;
      this.secccharityService.generalInfoModel.contactName = result.nonProfit.contactName;
      this.secccharityService.generalInfoModel.contactPhone = result.nonProfit.contactPhone;
      this.secccharityService.generalInfoModel.email = result.nonProfit.email;
      this.secccharityService.generalInfoModel.contactEmail  = result.nonProfit.contactEmail;
      this.secccharityService.generalInfoModel.phone = result.nonProfit.phone;
      this.secccharityService.generalInfoModel.url = result.nonProfit.url;

      //Services Provided
      this.secccharityService.generalInfoModel.serviceCodes = [...result.npServiceCodes] ;

      //Admin overhead
      this.secccharityService.generalInfoModel.percentage = result.nonProfit.percentage;

      //Description
      this.secccharityService.generalInfoModel.narrative = result.nonProfit.narrative ;

      //County codes
      this.secccharityService.generalInfoModel.countyCodes = [...result.npCountyCodes] ;

      //Statewide / National / International
      if(result.nonProfit.statewide) {
        this.secccharityService.generalInfoModel.locations = '0';
      }
      if(result.nonProfit.national) {
        this.secccharityService.generalInfoModel.locations = '1';
      }
      if(result.nonProfit.international) {
        this.secccharityService.generalInfoModel.locations = '2';
      }

      //Speak county codes
      this.secccharityService.generalInfoModel.speakCountyCodes = [...result.npSpeakCountyCodes] ;

      //Logo
      this.secccharityService.generalInfoModel.logo = (new Boolean(result.nonProfit.logo)).toString();

      //Speaker Bureau
      this.secccharityService.generalInfoModel.speakerBureau = (new Boolean(result.nonProfit.speakerBureau)).toString();

      //Tax ID
      this.secccharityService.generalInfoModel.taxID = result.nonProfit.taxID ;

  }

  popSubmit(result) {

    this.secccharityService.submitModel.submitterName = result.nonProfit.submitterName;
    this.secccharityService.submitModel.submitterJobTitle = result.nonProfit.submitterJobTitle;
    this.secccharityService.submitModel.submitDate = result.nonProfit.submitDate;
  }

  onChartReady(chart: GoogleChartComponent) {
    chart.chartWrapper.getChart();
  }

  ngOnDestroy() {

    if (this.appsSummarySubscription != undefined)
                  this.appsSummarySubscription.unsubscribe();

    if (this.appsSearchSummarySubscription != undefined)
                  this.appsSearchSummarySubscription.unsubscribe();
  }

}
