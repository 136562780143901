export class FinCertsModel {
  public constructor() {}

  respI: boolean; // H on page --> I in code
  respJ: boolean;
  respK: number;

  respL: boolean;
  l_A: number; //management
  l_B: number; //fundraising
               // ( missing L_C )
  l_D: number; //total revenue
  explainL: string;

  respM: boolean;
  explainM: string;

  respN: boolean;
  explainN: string;

  respO: boolean;
}
