<tbody style="width: 100%; display: block">
  <tr style="width: 100%; display: block">
    <td style="width: 100%; display: block; text-align: center">
      <h3>List of Applications</h3>
    </td>
  </tr>
  <tr style="width: 100%; display: block">
    <td>
      <table class="adminBand" width="100%" cellpadding="1" cellspacing="1">
        <tbody>
          <tr>
            <td valign="middle" align="left" width="28">
              <img
                name="imgAdd"
                id="imgAdd"
                src="assets/img/add-np.png"
                style="
                  height: 25px;
                  width: 25px;
                  border-width: 0px;
                  cursor: pointer;
                "
                (click)="addNewCharity()"
              />
            </td>
            <td valign="middle">
              <a id="lbbAdd">Add New Charity</a>
            </td>
            <td></td>
          </tr>
        </tbody>
      </table>
    </td>
  </tr>


  <tr>
    <!-- New Search bar row-->
    <td>
      <mat-form-field appearance="outline">
        <mat-label>Search by Reference #</mat-label>
        <input
          matInput
          placeholder="Reference #"
          [(ngModel)]="refno"
          (keyup.enter)="searchApplicationsSummary()"
        />
        <mat-icon
          class="material-icons search"
          matSuffix
          (click)="searchApplicationsSummary()"
          >search</mat-icon
        >
      </mat-form-field>
    </td>
    <td>
      <mat-form-field appearance="outline">
        <mat-label>Search by Title</mat-label>
        <input
          matInput
          placeholder="Title"
          [(ngModel)]="title"
          (keyup.enter)="searchApplicationsSummary()"
        />
        <mat-icon
          class="material-icons search"
          matSuffix
          (click)="searchApplicationsSummary()"
          >search</mat-icon
        >
      </mat-form-field>
    </td>
  </tr>

  <tr style="width: 100%; display: block">
    <td style="width: 100%; display: block">
      <div id="GoogleChartTable">
        <google-chart
          #applicationsList
          [type]="chartParams.type"
          [data]="chartParams.data"
          [columns]="chartParams.chartColumns"
          [options]="chartParams.options"
          (ready)="onChartReady(applicationsList)"
        >
        </google-chart>
      </div>
    </td>
  </tr>

  <tr>
    <td>&nbsp;</td>
  </tr>
</tbody>
