import { LoginComponent } from './secconlineapp/admin/applicationslist/login/login.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import {InstructionsComponent} from 'src/app/secconlineapp/instructions/instructions.component';
import {ApptypeComponent} from 'src/app/secconlineapp/apptype/apptype.component';
import {GeneralinfoComponent} from 'src/app/secconlineapp/generalinfo/generalinfo.component';
import {SoftfilereqComponent} from 'src/app/secconlineapp/softfilereq/softfilereq.component';
import {AdmincertsComponent} from 'src/app/secconlineapp/admincerts/admincerts.component';
import {FinancialcertsComponent} from 'src/app/secconlineapp/financialcerts/financialcerts.component';
import {SubmitComponent} from 'src/app/secconlineapp/submit/submit.component';
import {SeccfileuploaderComponent} from '../app/secconlineapp/seccfileuploader/seccfileuploader.component';
import {ThankyouComponent} from '../app/secconlineapp/thankyou/thankyou.component';
import {FinancialcertsfcComponent} from '../app/secconlineapp/financialcertsfc/financialcertsfc.component';



//Admin
import {ApplicationslistComponent} from '../app/secconlineapp/admin/applicationslist/applicationslist.component';



//Page navigation takes place in below order
const routes: Routes = [

  { path: '',redirectTo:'instructions',pathMatch:'full'},
  { path: 'instructions', component: InstructionsComponent },
  { path: 'apptype', component: ApptypeComponent },
  { path: 'generalinfo', component: GeneralinfoComponent },
  { path: 'softfilereq', component: SoftfilereqComponent },
  { path: 'admincerts', component: AdmincertsComponent },
  { path: 'financialcerts', component: FinancialcertsComponent },
  { path: 'submit', component: SubmitComponent },
  { path: 'fileupload', component: SeccfileuploaderComponent },
  { path: 'thankyou', component: ThankyouComponent },
  { path: 'financialcertsfc', component: FinancialcertsfcComponent },

  //Admin
  { path: 'admin/applist', component: ApplicationslistComponent },
  { path: 'admin', component: ApplicationslistComponent },
  { path: 'login', component: LoginComponent },


];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
