import { SessiontimeoutService } from './../../services/sessiontimeout.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { AdminCertsModel } from '../models/AdminCertsModel';
import { SecccharityService } from '../../services/secccharity.service';
import { SeccfileuploaderComponent } from './../seccfileuploader/seccfileuploader.component';


@Component({
  selector: 'app-admincerts',
  templateUrl: './admincerts.component.html',
  styleUrls: ['./admincerts.component.css'],
})
export class AdmincertsComponent implements OnInit {
  constructor(
    private secccharityService: SecccharityService,
    private sessiontimeoutService : SessiontimeoutService,
    private router: Router
  ) {}

  @ViewChild('attachA')
  attachARef: SeccfileuploaderComponent;

  @ViewChild('attachB')
  attachBRef: SeccfileuploaderComponent;

  @ViewChild('attachC')
  attachCRef: SeccfileuploaderComponent;

  @ViewChild('attachD')
  attachDRef: SeccfileuploaderComponent;


  isValidationError: boolean = false;
  errMessages: Array<string> = new Array<string>();

  fileType_adminCert: Array<string> = ['image','doc','pdf'];
  maxFileCount_adminCert: number = 3;
  maxFileSz_adminCert: number = 1024 * 1024;

  public adminCertsModel: AdminCertsModel;
  public adminCertsSubscription: Subscription = new Subscription();

  ngOnInit() {

    this.sessiontimeoutService.reset();
    //If previously saved to service object, copy from there.
    this.adminCertsModel = this.secccharityService.adminCertsModel;
    if (this.adminCertsModel == undefined) {

      this.adminCertsModel = new AdminCertsModel();
    }
    console.log('admin certs model');
    console.log(this.adminCertsModel);
  }

  ngAfterViewInit() {
    this.secccharityService.scrollToTopFast();
  }

  updateAdminCert() {

    this.isValidationError = false; // initialize
    this.secccharityService.adminCertsModel = this.adminCertsModel;

    this.adminCertsSubscription = this.secccharityService
      .updateAdminCert(this.adminCertsModel)
      .subscribe(
        (response) => {
          if (!response.isInvalidReq && response.status == 'success') {

            this.saveUploaderQueueState(); // Save file item queue to recreate queue if/when returning to page
            this.router.navigate(['financialcerts']);
          }
          if (response.invalidReq) {
            // set component validation and err flags
            this.isValidationError = true;
            this.errMessages = response.validationResult.errMessages;
            this.secccharityService.scrollToTop();
            console.log(this.errMessages);
          }
        },
        (err) => {
          console.log(err);
        }
      );
  }


  //Save file uploader queues to a map
  saveUploaderQueueState() {

    this.secccharityService.uploaderQueueMap.set('attachA',this.attachARef.uploader.queue);
    this.secccharityService.uploaderQueueMap.set('attachB',this.attachBRef.uploader.queue);
    this.secccharityService.uploaderQueueMap.set('attachC',this.attachCRef.uploader.queue);
    this.secccharityService.uploaderQueueMap.set('attachD',this.attachDRef.uploader.queue);
  }
  continue() {
    //save raw state to service object
    this.secccharityService.adminCertsModel = this.adminCertsModel;
    this.updateAdminCert();

  }

  ngOnDestroy() {
    if (this.adminCertsSubscription != undefined)
      this.adminCertsSubscription.unsubscribe();
  }
}
