
export class SubmitModel {

  submitterName : string = "";
  submitterJobTitle : string = "";
  submitDate : string = "";s
  captchaResponse : string = "";


}
